// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.facade-container {
	width: 100%;
	height: 100%;
	margin: auto;
	display: flex;
}

.vid-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	animation: skeleton-loading 1s linear 5 alternate;
}

.vid-container h1 {
	color: var(--alt-font-clr1);
}
.vid-container h2 {
	color: var(--font-clr1);
}

@keyframes skeleton-loading {
	0% {
		background-color: hsl(200, 20%, 80%);
	}
	100% {
		background-color: hsl(200, 20%, 95%);
	}
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/components/videoplayer/index.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;CACZ,YAAY;CACZ,aAAa;AACd;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,gBAAgB;CAChB,iDAAiD;AAClD;;AAEA;CACC,2BAA2B;AAC5B;AACA;CACC,uBAAuB;AACxB;;AAEA;CACC;EACC,oCAAoC;CACrC;CACA;EACC,oCAAoC;CACrC;AACD","sourcesContent":[".facade-container {\n\twidth: 100%;\n\theight: 100%;\n\tmargin: auto;\n\tdisplay: flex;\n}\n\n.vid-container {\n\twidth: 100%;\n\theight: 100%;\n\toverflow: hidden;\n\tanimation: skeleton-loading 1s linear 5 alternate;\n}\n\n.vid-container h1 {\n\tcolor: var(--alt-font-clr1);\n}\n.vid-container h2 {\n\tcolor: var(--font-clr1);\n}\n\n@keyframes skeleton-loading {\n\t0% {\n\t\tbackground-color: hsl(200, 20%, 80%);\n\t}\n\t100% {\n\t\tbackground-color: hsl(200, 20%, 95%);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
