import { MUIComponents } from './component';
import { darkThemePalette, lightThemePalette } from './palette';
import { MUITypography } from './typography';

export const qbraidLightTheme = {
	palette: lightThemePalette,
	typography: MUITypography,
	components: MUIComponents
};
export const qbraidDarkTheme = {
	palette: darkThemePalette,
	typography: MUITypography,
	components: MUIComponents
};
