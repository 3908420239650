import { ArrowBack, Error } from '@mui/icons-material';
import {
	Box, Button, Container, Grid, Typography 
} from '@mui/material';
import React from 'react';

function ErrorFallback({ error }) {
	const body = document.querySelector('body');
	const isDark = body?.classList?.contains('darkmode');
	const handleClick = () => {
		window.history.back();
		window.location.reload();
	};
	return (
		<Container
			maxWidth="sm"
			sx={{
				minHeight: '100vh',
				height: '100%',
				display: 'flex',
				alignItems: 'center'
			}}
		>
			<Grid container justifyContent="center" spacing={2}>
				<Grid item xs={12} display="flex" justifyContent="center">
					<Error
						color="error"
						sx={{ width: 64, height: 64 }}
						cy-data="error-fallback-icon"
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography
						color="text.secondary"
						fontSize={18}
						textAlign="center"
						cy-data="error-fallback-label"
					>
						There is a problem processing the request. Please try again.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Box
						padding={2}
						borderRadius={1}
						sx={(theme) => ({
							border: `1px solid ${theme.palette.error.main}`,
							backgroundColor: `${theme.palette.error.light}20`
						})}
					>
						<Typography
							sx={{ color: isDark ? 'white' : 'indianred' }}
							fontSize={14}
							cy-data="error-fallback-error-msg"
						>
							{error.message}
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} display="flex" justifyContent="center">
					<Button
						variant="contained"
						color="secondary"
						startIcon={<ArrowBack />}
						onClick={handleClick}
						size="large"
						sx={{ textTransform: 'unset' }}
						cy-data="error-fallback-btn"
					>
						Go back to last page
					</Button>
				</Grid>
			</Grid>
		</Container>
	);
}

export default ErrorFallback;
