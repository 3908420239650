import {
	ADD_QUANTUM_LINK_FAILED,
	ADD_QUANTUM_LINK_SUCCESS,
	ADD_QUANTUM_PROVIDER_FAILED,
	ADD_QUANTUM_PROVIDER_SUCCESS,
	ADD_TO_DOWNLOAD_LIST,
	CHANGE_NAVIGATION,
	DELETE_QUANTUM_LINK_FAILED,
	DELETE_QUANTUM_LINK_SUCCESS,
	DELETE_QUANTUM_MAIN_DATA_FAILED,
	DELETE_QUANTUM_MAIN_DATA_SUCCESS,
	DOWNLOAD_GIT_REPO_CONTENT_FAILED,
	DOWNLOAD_GIT_REPO_CONTENT_SUCCESS,
	FETCH_QUANTUM_DOCS_LINK_DATA_FAILED,
	FETCH_QUANTUM_DOCS_LINK_DATA_SUCCESS,
	FETCH_QUANTUM_DOCS_MAIN_DATA_FAILED,
	FETCH_QUANTUM_DOCS_MAIN_DATA_SUCCESS,
	FETCH_TUTORIAL_DATA_FAILED,
	FETCH_TUTORIAL_DATA_SUCCESS,
	HIDE_LOADING_LINK_DATA,
	HIDE_LOADING_MAIN_DATA,
	HIDE_LOADING_TUTORIAL_DATA,
	REMOVE_FROM_DOWNLOAD_LIST,
	SET_DOCS_TUTOR_SNACKBAR_STATUS,
	SHOW_LOADING_LINK_DATA,
	SHOW_LOADING_MAIN_DATA,
	SHOW_LOADING_TUTORIAL_DATA,
	UPDATE_QUANTUM_PROVIDER_FAILED,
	UPDATE_QUANTUM_PROVIDER_SUCCESS
} from '../Types/docsTutorialType';

const initialState = {
	mainDataLoading: false,
	linkDataLoading: false,
	mainDataError: false,
	linkDataError: false,
	tutorialDataLoading: false,
	tutorialDataError: false,
	navigation: [],
	mainData: [],
	linkScreenData: [],
	tutorialsContent: [],
	downloadingContent: [],
	docTutorSnackBar: {
		message: '',
		severity: false,
		open: false
	}
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_DOCS_TUTOR_SNACKBAR_STATUS: {
			const { message, severity, status } = action.payload;
			return {
				...state,
				docTutorSnackBar: {
					message: message,
					open: status,
					severity: severity
				}
			};
		}

		case SHOW_LOADING_MAIN_DATA:
			return {
				...state,
				mainDataLoading: true
			};
		case HIDE_LOADING_MAIN_DATA:
			return {
				...state,
				mainDataLoading: false
			};
		case SHOW_LOADING_LINK_DATA:
			return {
				...state,
				linkDataLoading: true
			};
		case HIDE_LOADING_LINK_DATA:
			return {
				...state,
				linkDataLoading: false
			};
		case SHOW_LOADING_TUTORIAL_DATA:
			return {
				...state,
				tutorialDataLoading: true
			};
		case HIDE_LOADING_TUTORIAL_DATA:
			return {
				...state,
				tutorialDataLoading: false
			};
		case ADD_TO_DOWNLOAD_LIST:
			return {
				...state,
				downloadingContent: [...state.downloadingContent, action.payload]
			};
		case REMOVE_FROM_DOWNLOAD_LIST:
			return {
				...state,
				downloadingContent: state.downloadingContent.filter(
					(item) => item !== action.payload
				)
			};
		case CHANGE_NAVIGATION:
			return {
				...state,
				navigation: action.payload
			};
		case FETCH_QUANTUM_DOCS_MAIN_DATA_SUCCESS:
			return {
				...state,
				mainData: action.payload
			};
		case FETCH_QUANTUM_DOCS_MAIN_DATA_FAILED:
			return {
				...state
			};
		case FETCH_QUANTUM_DOCS_LINK_DATA_SUCCESS:
			return {
				...state,
				linkScreenData: action.payload
			};
		case FETCH_QUANTUM_DOCS_LINK_DATA_FAILED:
			return {
				...state
			};
		case FETCH_TUTORIAL_DATA_SUCCESS:
			return {
				...state,
				tutorialsContent: action.payload
			};
		case FETCH_TUTORIAL_DATA_FAILED:
			return {
				...state
			};
		case DOWNLOAD_GIT_REPO_CONTENT_SUCCESS: {
			const modifiedTutorialsContent = state.tutorialsContent.map((content) => {
				if (content.path === action.payload) {
					return { ...content, is_locally_available: true };
				}
				return content;
			});
			return { ...state, tutorialsContent: modifiedTutorialsContent };
		}
		case DOWNLOAD_GIT_REPO_CONTENT_FAILED:
			return {
				...state
			};
		case ADD_QUANTUM_PROVIDER_SUCCESS:
			return {
				...state
			};
		case ADD_QUANTUM_PROVIDER_FAILED:
			return {
				...state
			};
		case UPDATE_QUANTUM_PROVIDER_SUCCESS:
			return {
				...state
			};
		case UPDATE_QUANTUM_PROVIDER_FAILED:
			return {
				...state
			};
		case ADD_QUANTUM_LINK_SUCCESS:
			return {
				...state
			};
		case ADD_QUANTUM_LINK_FAILED:
			return {
				...state
			};
		case DELETE_QUANTUM_LINK_SUCCESS:
			return {
				...state
			};
		case DELETE_QUANTUM_LINK_FAILED:
			return {
				...state
			};
		case DELETE_QUANTUM_MAIN_DATA_SUCCESS:
			return {
				...state
			};
		case DELETE_QUANTUM_MAIN_DATA_FAILED:
			return {
				...state
			};
		default:
			return state;
	}
}
