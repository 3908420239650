/**
 * This component is used to clone a public repository from GitHub to qBraid Lab.
 * @module GitHubIntegration
 * @category Integrations
 * @subcategory GitHub
 * @requires react
 * @copyright 2024 qBraid Development Team
 */
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import { axiosClient, LAB_URL } from '../../../utils/axiosClient';

function GitHubIntegration() {
	const navigate = useNavigate();
	const { search } = useLocation();
	const { userDetails } = useSelector((state) => state.userReducer);
	// eslint-disable-next-line unused-imports/no-unused-vars
	const [showModal, setShowModal] = useState(false);
	const gitHubUrl = new URLSearchParams(search).get('gitHubUrl');
	let redirectUrl = new URLSearchParams(search).get('redirectUrl');
	let user;
	let repoName;
	let splitGitUrl;
	if (gitHubUrl) {
		splitGitUrl = gitHubUrl?.split('/');
		repoName = splitGitUrl?.pop()?.replace(/(.git$)/, '');
		user = splitGitUrl[splitGitUrl.length - 1];
	}

	const checkForRedirect = async () => {
		if (redirectUrl) {
			// Strip leading slashes
			redirectUrl = redirectUrl.replace(/^\/+/, '');
			// Check if file exists on github
			try {
				const response = await fetch(
					`https://api.github.com/repos/${user}/${repoName}/contents/${redirectUrl}`
				);
				if (!response.ok) {
					return await Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: 'The file or folder specified in the redirect url was not found. You lab may not open up to the correct file or folder. Do you want to proceed anyway?',
						confirmButtonColor: 'var(--brand-color-1)',
						showCancelButton: true,
						showConfirmButton: true,
						reverseButtons: true,
						confirmButtonText: 'Proceed anyway',
						cancelButtonColor: 'var(--brand-color-2)',
						cancelButtonText: 'Go back'
					}).then((result) => {
						console.log(result, 'result');
						if (result.isDismissed) {
							navigate(window.history.go(-1));
						} else if (result.isConfirmed) {
							return true;
						}
					});
				}
				return true;
			} catch (error) {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: `Error: ${error.message}`,
					confirmButtonColor: 'var(--brand-color-1)',
					allowOutsideClick: true
				});
				return false;
			}
		}
		return true;
	};

	const fetchGitRepo = useCallback(async () => {
		Swal.fire({
			cancelButtonText: 'Go to Dashboard',
			allowOutsideClick: false,
			imageWidth: '20%',
			imageUrl: 'https://cdn-icons-png.flaticon.com/512/25/25231.png',
			reverseButtons: true,
			title: 'Get ready to be launched to qBraid Lab.😀',
			text: `Hold on tight...`,
			didOpen: async () => {
				Swal.showLoading();
				let data = await fetch(
					`https://api.github.com/repos/${user}/${repoName}`
				);
				// convert data to json
				data = await data.json();

				if (data.id) {
					try {
						const result = await axiosClient().post('/api/lab/github/clone', {
							repoUrl: gitHubUrl,
							labUrl: LAB_URL
						});

						Swal.fire({
							icon: 'success',
							title: 'Systems calibrated ... all systems go🚀 ',
							showConfirmButton: false,
							timer: 1500,
							didOpen: () =>
								redirectUrl
									? window.location.replace(
										`${result.data.redirect}/${redirectUrl}`
									  )
									: window.location.replace(result.data.redirect)
						});
					} catch (err) {
						Swal.hideLoading();
						Swal.showValidationMessage(`Request failed: ${err.message}`);
						Swal.getCancelButton();
					}
				} else {
					Swal.hideLoading();
					Swal.showValidationMessage(
						`Request failed to clone public repository: ${data.message}`
					);
					Swal.getCancelButton();
				}
			}
		});
	});

	const handleGitHubClone = async () => {
		if (!gitHubUrl) return;

		if (!userDetails?.email) {
			Swal.fire({
				allowOutsideClick: false,
				showCancelButton: true,
				reverseButtons: true,
				cancelButtonText: 'Go back',
				confirmButtonColor: 'var(--brand-color-1)',
				title: 'You need to sign in to clone a repository',
				text: 'Redirect back to the dashboard',
				icon: 'info'
			}).then((result) => {
				if (result.isDismissed) {
					// navigate(window.history.go(-1));
				}
			});
			return; // Exit early
		}

		const redirect = await checkForRedirect();
		if (redirect) {
			try {
				await fetchGitRepo(gitHubUrl);
			} catch (error) {
				console.error(error);
			}
		}
	};

	useEffect(() => {
		if (gitHubUrl && !userDetails?.email) {
			setShowModal(true);
		} else {
			handleGitHubClone();
		}
	}, [gitHubUrl, userDetails]);

	return null;
}

export default GitHubIntegration;
