export const lightThemePalette = {
	mode: 'light',
	primary: {
		main: '#4144bb'
	},
	secondary: {
		main: '#46096f'
	},
	background: {
		alternate: '#faf7fd'
	},
	text: {
		auth: {
			login100: '#2E3338',
			signup100: '#414345',
			signup200: '#2e3338',
			forget100: '#444444',
			forget200: '#555555'
		}
	}
};

export const darkThemePalette = {
	mode: 'dark',
	primary: {
		main: '#4144bb'
	},
	secondary: {
		main: '#46096f'
	},
	background: {
		alternate: '#1e1e1e'
	},
	text: {
		auth: {
			login100: '#c2c2c2',
			signup100: '#c1c7cd',
			signup200: '#dddddd',
			forget100: '#cecece',
			forget200: '#b6b6b6'
		}
	}
};
