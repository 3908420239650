import {
	FILES_DATA_FAIL,
	FILES_DATA_LOADING,
	FILES_GET_DATA,
	SET_FILE_PATHNODE,
	SET_TUTORIAL_PATH,
	TUTORIAL_LOADING,
	TUTORIAL_LOAD_FAILED,
	TUTORIAL_LOAD_SUCCESS
} from '../Types/filesTypes';

const initialState = {
	isLoading: true,
	tree: [],
	environmentsList: [], // required for filtering by environment
	isSuccess: false,
	pathNode: ['/'],
	tutorialTree: [],
	tutorialPath: ['/qbraid-tutorials/'],
	tutorialLoading: true,
	tutorialSuccess: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case FILES_DATA_LOADING:
			return {
				...state,
				isLoading: true
			};
		case FILES_DATA_FAIL:
			return {
				...state,
				isLoading: false,
				isSuccess: false
			};
		case FILES_GET_DATA:
			return {
				...state,
				tree: action.payload.files,
				environmentsList: action.payload.environmentList,
				isLoading: false,
				isSuccess: true
			};
		case SET_FILE_PATHNODE:
			return {
				...state,
				pathNode: action.payload
			};
		case TUTORIAL_LOADING:
			return {
				...state,
				tutorialLoading: true,
				tutorialSuccess: false
			};
		case TUTORIAL_LOAD_FAILED:
			return {
				...state,
				tutorialLoading: false,
				tutorialSuccess: false
			};
		case TUTORIAL_LOAD_SUCCESS:
			return {
				...state,
				tutorialTree: action.payload,
				tutorialLoading: false,
				tutorialSuccess: true
			};
		case SET_TUTORIAL_PATH:
			return {
				...state,
				tutorialPath: action.payload
			};
		default:
			return state;
	}
}
