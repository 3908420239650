/**
 * @fileoverview This file contains the code for the BillingCallback component.
 * It includes functionality for handling the callback after a billing transaction.
 *
 * The BillingCallback component includes the following features:
 * - Displaying success or cancellation messages based on the URL parameters
 * - Updating the user's billing information and credits
 * - Navigating to the appropriate page after the transaction
 *
 * @copyright (C) 2024 qBraid Development Team
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Grid } from '@mui/material';
import {
	getUserBilling,
	getUserCredits
} from '../../Redux/Action/billingAction';
import { sendRGAEvent } from '../../utils/googleAnalytics';
import { sendErrorEmail } from '../../Redux/Action/userAction';
import { currentDomain } from '../../utils/domainBasedInfo/currentDomain';

function BillingCallback() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const billingSelector = useSelector((state) => state.billingReducer);
	const { userDetails } = useSelector((state) => state.userReducer);

	useEffect(() => {
		sendRGAEvent('Billing', 'Page View');
	}, [billingSelector.credits]);

	useEffect(() => {
		try {
			if (window.location.href.includes('success')) {
				Swal.fire({
					confirmButtonColor: 'var(--brand-color-1)',
					title: `Thank you for purchasing credits! You can now use them on ${currentDomain.title} Lab.😀`,
					icon: 'success'
				}).then(() => {
					if (userDetails?.email) {
						const redirectURI = window.location.pathname.replace('success', '');
						navigate(redirectURI);
						dispatch(getUserBilling()).then(() => {
							// get user credits
							dispatch(getUserCredits());
						});
					}
				});
			} else if (window.location.href.includes('cancel')) {
				Swal.fire({
					confirmButtonColor: 'var(--brand-color-1)',
					title: 'Cancelled! You have not made any purchases.',
					icon: 'error'
				}).then(() => {
					const redirectURI = window.location.pathname.replace('cancel', '');
					navigate(redirectURI);
					dispatch(getUserBilling()).then(() => {
						// get user credits
						dispatch(getUserCredits());
					});
				});
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.log('Something went wrong!', err);
			dispatch(sendErrorEmail(err));
		}
	}, [userDetails]);

	return (
		<Grid item xs={12} className="billing-grid">
			<div className="billing-success-container" />
		</Grid>
	);
}

export default BillingCallback;
