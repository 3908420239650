import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Amplify } from 'aws-amplify';
import { HelmetProvider } from 'react-helmet-async';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Navigate from './navigation';
import Snackbar from './components/snackbar/index';
import Loading from './components/fallbackUI/Loading';
import Store from './Redux/Store';
import PublicHeadTags from './components/publicHeadTags';
import { closeSnack } from './Redux/Action/loadingErrMsgAction';
import { currentDomain } from './utils/domainBasedInfo/currentDomain';
import { getAccountURL } from './utils/utilityFunction';
import ErrorFallback from './components/fallbackUI/Error';
import { ACCOUNT_URL } from './utils/axiosClient';
import Openreplay from './components/openReplay';
import CookieConsent from './components/cookieConsent';
import './index.css';
import MUIThemeProvider from './components/themeWrapper';

const PartnerAuth = React.lazy(() => import('./pages/partner-auth-page'));
const redirectSignInUrl = getAccountURL(window.location.pathname);
const redirectSignOutUrl = `${ACCOUNT_URL.slice(0, -1)}${
	window.location.pathname
}`;

Amplify.configure({
	Auth: {
		Cognito: {
			userPoolId: currentDomain.userPoolId,
			userPoolClientId: currentDomain.userPoolClientId,
			loginWith: {
				oauth: {
					domain: `auth${currentDomain.domain}`,
					redirectSignIn: [redirectSignInUrl],
					redirectSignOut: [redirectSignOutUrl],
					responseType: 'code',
					scopes: ['email', 'openid']
				}
			}
		}
	}
});

function App() {
	return (
		<HelmetProvider>
			<PublicHeadTags />
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<Provider store={Store}>
					<MUIThemeProvider>
						<Router>
							<Suspense fallback={<Loading />}>
								<CookieConsent positionY="bottom" positionX="left" />
								<Openreplay>
									<Routes>
										<Route
											path="/auth/login/partner/:partner/*"
											element={<PartnerAuth />}
										/>
										<Route path="/*" element={<Navigate />} />
									</Routes>
								</Openreplay>
							</Suspense>
						</Router>
						<SnackbarComponent />
					</MUIThemeProvider>
				</Provider>
			</ErrorBoundary>
		</HelmetProvider>
	);
}

export function SnackbarComponent() {
	const { isApiDown, message } = useSelector((state) => state.lemReducer);
	const dispatch = useDispatch();
	return (
		<Snackbar
			openSnack={isApiDown}
			handleCloseSnack={() => {
				dispatch(closeSnack());
			}}
			snackMessage={message}
			snackColor={!isApiDown}
		/>
	);
}

export default App;
