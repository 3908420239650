export const FETCH_ORGLIST_LOADING = 'FETCH_ORGLIST_LOADING';
export const FETCH_ORGLIST_SUCCESS = 'FETCH_ORGLIST_SUCCESS';
export const FETCH_ORGLIST_FAILED = 'FETCH_ORGLIST_FAILED';
export const ORGLIST_LOADING_STOP = 'ORGLIST_LOADING_STOP';

export const ADD_TO_ACTIVE_USER_LOADING_ORGS =
	'ADD_TO_ACTIVE_USER_LOADING_ORGS';
export const REMOVE_FROM_ACTIVE_USER_LOADING_ORGS =
	'REMOVE_FROM_ACTIVE_USER_LOADING_ORGS';
export const FETCH_ORG_USER_LIST_SUCCESS = 'FETCH_ORG_USER_LIST_SUCCESS';

export const SET_SELECTED_ORG = 'SET_SELECTED_ORG';
export const SET_SELECTED_ORG_CURRENT_USER_ROLE =
	'SET_SELECTED_ORG_CURRENT_USER_ROLE';
export const SET_SELECTED_ORG_CURRENT_USER_DEDUCTION_REQUEST =
	'SET_SELECTED_ORG_CURRENT_USER_DEDUCTION_REQUEST';
