import { createTheme } from '@mui/material';
import { MUIComponents } from './component';
import { darkThemePalette, lightThemePalette } from './palette';
import { MUITypography } from './typography';

export const qusteamLightTheme = {
	palette: lightThemePalette,
	typography: MUITypography,
	components: MUIComponents,
	shadows: [
		...createTheme().shadows.slice(0, 24),
		'0 20px 25px -5px rgba(0, 0, 0, .1), 0 8px 10px -6px rgba(0, 0, 0, .1)'
	]
};
export const qusteamDarkTheme = {
	palette: darkThemePalette,
	typography: MUITypography,
	components: {
		...MUIComponents,
		MuiButton: {
			...MUIComponents.MuiButton,
			variants: [
				...MUIComponents.MuiButton.variants,
				// mui outlined button component is formatted to have more readable texture, in questeam darkmode,
				// this will override all outlined buttons, which were not readable in dark mode.
				{
					props: { variant: 'outlined' },
					style: {
						color: '#c2c2c2',
						borderColor: '#c2c2c2',
						':hover': { borderColor: 'gray' }
					}
				}
			]
		}
	}
};
