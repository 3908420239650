// this reducer controls the UI of the files drawer

import {
	DRAWER_FLOAT,
	DRAWER_HALF,
	DRAWER_MAXIMIZE,
	DRAWER_MINIMIZE
} from '../Types/filesDrawerTypes';

const initialState = {
	drawerSize: DRAWER_HALF
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case DRAWER_HALF:
			return {
				drawerSize: DRAWER_HALF
			};
		case DRAWER_MAXIMIZE:
			return {
				drawerSize: DRAWER_MAXIMIZE
			};
		case DRAWER_MINIMIZE:
			return {
				drawerSize: DRAWER_MINIMIZE
			};
		case DRAWER_FLOAT:
			return {
				drawerSize: DRAWER_FLOAT
			};
		default:
			return state;
	}
}
