/* eslint-disable react/jsx-indent */
/* eslint-disable no-lonely-if */
import { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	startServer,
	postComputeLabtoken
} from '../../Redux/Action/computeAction';
import { getLabMaintenanceStatus } from '../../Redux/Action/maintenanceAction';
import { sendRGAEvent } from '../../utils/googleAnalytics';
import { email, LAB_URL } from '../../utils/axiosClient';

export const useLaunchLab = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [openCredit, setOpenCredit] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userDetails = useSelector((state) => state.userReducer.userDetails);
	const selectedProfileObj = useSelector(
		(state) => state.computeReducer.selectedProfileObj
	);
	const billingSelector = useSelector((state) => state.billingReducer);
	const lab = useSelector((state) => state.maintenanceReducer.lab);

	const initializeUserData = useCallback(() => {
		if (email) {
			dispatch(getLabMaintenanceStatus());
		}
	}, [dispatch, email]);

	useEffect(() => {
		initializeUserData();
	}, [initializeUserData]);

	const handleLaunchLab = async () => {
		sendRGAEvent('Launch Lab', 'Button Click');

		if (lab?.isUnderMaintenance) {
			navigate('/maintenance');
			return;
		}

		const newTab = window.open();
		const url = userDetails?.metadata?.newUser
			? `${LAB_URL}/hub/home`
			: LAB_URL;

		if (userDetails.email || email) {
			setIsSubmitting(true);
			try {
				const response = await postComputeLabtoken();
				if (response?.status === 200) {
					if (
						selectedProfileObj?.slug.includes('GPU') &&
						billingSelector.qbraidCredits < 200
					) {
						setIsSubmitting(false);
						Swal.fire({
							title: 'Insufficient Credits',
							text: 'You do not have enough credits to start a GPU server.',
							icon: 'warning',
							showCancelButton: true,
							reverseButtons: true,
							confirmButtonColor: 'Green',
							confirmButtonText: 'Buy Credits',
							cancelButtonColor: 'Red',
							cancelButtonText: 'Cancel'
						}).then((result) => {
							if (result.isConfirmed) {
								handleOpenCredit();
							}
						});
						return;
					}

					startServer({
						token: response?.data?.token?.token,
						slug: selectedProfileObj.slug || '2vCPU_4GB'
					})
						.then((resp) => {
							if (resp?.status === 201 || resp?.status === 202) {
								newTab.location.href = url;
								setIsSubmitting(false);
								Swal.fire({
									icon: 'success',
									title: 'Server Started',
									text: `Redirecting to Lab ${LAB_URL}`,
									timer: 2000
								});
							}
						})
						.catch((err) => handleError(err, url, newTab));
				}
			} catch (err) {
				setIsSubmitting(false);
				newTab.location.href = url;
			}
		}
	};

	const handleOpenCredit = () => {
		setOpenCredit(true);
	};

	const handleCloseCredit = () => {
		setOpenCredit(false);
	};

	const handleError = (err, url, newTab) => {
		setIsSubmitting(false);
		if (err?.response?.status === 400) {
			Swal.fire({
				title: 'Redirecting',
				text: 'please wait....',
				icon: 'success',
				timer: 2000
			});
			newTab.location.href = url;
		} else if (err?.response?.status === 403) {
			Swal.fire({
				title: 'Token not Authorized please try again',
				timer: 2000
			});
			newTab.close();
		} else if (err?.response?.status === 402) {
			newTab.close();
			Swal.fire({
				title: 'Insufficient Credits',
				text: 'You do not have enough credits to start a GPU server.',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: 'Green',
				confirmButtonText: 'Buy Credits',
				cancelButtonColor: 'Red',
				cancelButtonText: 'Cancel'
			}).then((result) => {
				if (result.isConfirmed) {
					handleOpenCredit();
				}
			});
		} else {
			if (userDetails?.metadata?.newUser) {
				Swal.fire('Redirecting to Lab', 'please wait....');
				window.open(`${LAB_URL}/hub/home`, '_blank');
			} else {
				Swal.fire('Redirecting', 'please wait....');
				window.open(`${LAB_URL}`, '_blank');
			}
		}
	};
	return {
		handleLaunchLab,
		handleOpenCredit,
		handleCloseCredit,
		isSubmitting,
		openCredit
	};
};
