/**
 * @fileOverview This file contains the axiosClient utility function and related constants for making HTTP requests.
 * @module axiosClient
 */

import axios from 'axios';
import Cookies from 'universal-cookie';
import { qbraidConfig as qbraid } from './domainBasedInfo/qbraid';
import { qusteamConfig as qusteam } from './domainBasedInfo/qusteam';

axios.defaults.withCredentials = false;
let apiURL = '';
let labURL = '';
let qbookURL = '';
let accountURL = '';

/**
 * The domain of the application. If you're running the application locally, make sure to add the domain to the .env file.
 * @type {string}
 */
export const domain = process.env.REACT_APP_DOMAIN;

/**
 * Configuration options for the localhost environment.
 * @type {Object}
 */
const localhost = {
	account: 'http://localhost:3000/',
	api: 'http://localhost:3001',
	qbook: 'https://qbook-staging.qbraid.com',
	lab: 'http://localhost:8080'
};

let current = {};

if (domain?.toLowerCase() === qbraid.name?.toLowerCase()) {
	// use qbraid option
	current = qbraid;
} else if (domain?.toLowerCase() === qusteam.name?.toLowerCase()) {
	// use qusteam option
	current = qusteam;
}

const path = window.location.href;
const live = !!path.match(current.live);
const staging = !!path.match(current.staging);

if (live) {
	accountURL = current.account;
	apiURL = current.api;
	labURL = current.lab;
	qbookURL = current.qbook;
} else if (staging) {
	accountURL = current.accountStaging;
	apiURL = current.apiStaging;
	labURL = current.labStaging;
	qbookURL = current.qbookStaging;
} else {
	accountURL = localhost.account;
	// switch between localhost api and staging api.
	// apiURL = qbraid.api;
	// apiURL = localhost.api;
	apiURL = qbraid.apiStaging;
	labURL = qbraid.labStaging;
	qbookURL = localhost.qbook;
}
export const ENV = live ? 'live' : staging ? 'staging' : 'local';
export const LAB_URL = labURL;
export const API_URL = apiURL;
export const QBOOK_URL = qbookURL;
export const ACCOUNT_URL = accountURL;
export const X_URL = 'https://x.com/qbraid_official';
export const DISCORD_URL = 'https://discord.gg/9jpmpeEV65';
export const LINKED_IN_URL =
	'https://www.linkedin.com/company/qbraid-official/';
export const GIT_HUB_URL = 'https://github.com/qbraid';
export const DOCS_URL = 'https://docs.qbraid.com/lab/user-guide/overview';
export const FOOTER_WORK_WITH_US_URL = 'https://qbraid.com/careers/';
export const HOME_PAGE_URL = 'https://qbraid.com';
export const XANADU_LAB_URL = 'https://www.lab.xanadu.qbraid.com/';

const cookies = new Cookies();
export const email = cookies.get('EMAIL');
export const refreshToken = cookies.get('REFRESH');

/**
 * The axios client function for making HTTP requests to api.
 * @function axiosClient
 */
export function axiosClient() {
	// gets the email and refresh cookies
	const defaultOptions = {
		baseURL: apiURL,
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',

			// "Access-Control-Allow-Credentials": true,
			accept: 'application/json',
			'refresh-token': refreshToken,
			// eslint-disable-next-line
			email: email,
			domain: domain
		}
	};

	const instance = axios.create(defaultOptions);

	// Set the AUTH token for any request
	instance.interceptors.request.use((config) => {
		config.headers.common = {
			// "x-auth-token": `${localStorage.getItem("token")}`,
		};
		return config;
	});

	return instance;
}

export default axiosClient;
