import { axiosClient } from '../../utils/axiosClient';
import { LAB_MAINTENANCE } from '../Types/maintenanceTypes';

export const getLabMaintenanceStatus = () => async (dispatch) => {
	try {
		const response = await axiosClient().get('/api/maintenance/lab');
		if (response.status === 200) {
			return dispatch(LabMaintanenceSuccess(response?.data?.maintenance));
		}
	} catch (err) {
		console.log(err);
	}
};

export const LabMaintanenceSuccess = (payload) => ({
	type: LAB_MAINTENANCE,
	payload
});

// TODO account and qbook maintenance status
