import {
	PAPERS_DATA_LOADING,
	PAPERS_DATA_SUCCESS,
	PAPERS_DATA_FAIL
} from '../Types/paperTypes';

const initialState = {
	isPapersloading: true,
	papersArray: [],
	success: true
};
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case PAPERS_DATA_LOADING:
			return {
				...state,
				isPapersLoading: true
			};
		case PAPERS_DATA_SUCCESS:
			return {
				...state,
				isPapersLoading: false,
				success: true,
				papersArray: action.payload
			};
		case PAPERS_DATA_FAIL:
			return {
				...state,
				isPapersLoading: false,
				success: false
			};
		default:
			return state;
	}
}
