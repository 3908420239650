import {
	JOBS_DATA_LOADING,
	JOBS_DATA_SUCCESS,
	JOBS_DATA_FAIL
} from '../Types/userJobsTypes';

const initialState = {
	isLoading: false,
	userJobsArray: [],
	success: true
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case JOBS_DATA_LOADING:
			return {
				...state,
				isLoading: true
			};
		case JOBS_DATA_SUCCESS:
			return {
				...state,
				isLoading: false,
				userJobsArray: action.payload.jobsArray || [],
				success: true
			};
		case JOBS_DATA_FAIL:
			return {
				...state,
				isLoading: false,
				success: false
			};
		default:
			return state;
	}
}
