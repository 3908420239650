/**
 * MUI theme object
 * @module src/styles/MUITheme/theme.js
 * @description This module exports the light and dark theme objects for the MUI theme provider.
 */
import { createTheme } from '@mui/material/styles';
import { domain } from '../../utils/axiosClient';
import { qbraidDarkTheme, qbraidLightTheme } from './qbraid/themeObject';
import { qusteamDarkTheme, qusteamLightTheme } from './qusteam/themeObject';

const lightThemeObject =
	domain?.toLowerCase() === 'qbraid' ? qbraidLightTheme : qusteamLightTheme;
const darkThemeObject =
	domain?.toLowerCase() === 'qbraid' ? qbraidDarkTheme : qusteamDarkTheme;

export const lightTheme = createTheme(lightThemeObject);
export const darkTheme = createTheme(darkThemeObject);
