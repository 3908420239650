import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box,
	Button,
	Divider,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import { SetSelectedComputeProfileObj } from '../../../../Redux/Action/computeAction';
import quantum1 from '../../../../assets/images/quantum1.webp';
import quantum8 from '../../../../assets/images/quantum8.png';
import { useLaunchLab } from '../../../../components/common/useLaunchLab';

function ComputeInstance() {

	const { handleLaunchLab, isSubmitting} = useLaunchLab();
	const { computeProfile, selectedProfileObj } = useSelector(
		(state) => state.computeReducer
	);
	const dispatch = useDispatch();

	const handleSelectComputeInstance = (computeObj) => {
		dispatch(SetSelectedComputeProfileObj(computeObj));
	};

	return (
		<Stack
			paddingX={2.5}
			paddingY={2}
			gap={1}
			flex={1}
			cy-data="compute-instance-card"
		>
			<Box
				id="compute-instance-header"
				display="flex"
				flexDirection="row"
				flexWrap="wrap"
				gap={1}
			>
				<Stack>
					<Typography
						color="text.primary"
						fontWeight={900}
						fontSize={20}
						fontFamily="Nunito Sans"
						cy-data="compute-list-title"
					>
						Compute
					</Typography>
					<Typography
						color="text.secondary"
						fontWeight={400}
						fontSize={11}
						cy-data="compute-list-dec"
					>
						Manage and launch compute instances
					</Typography>
				</Stack>
			</Box>
			<Divider />
			<Box id="compute-instance-options" minHeight={170} flex={1}>
				<List
					dense
					sx={{
						pt: 0,
						maxHeight: 170,
						overflow: 'auto',
						scrollbarGutter: true
					}}
				>
					{!computeProfile.length
						? Array(3)
							.fill('')
							.map((item, index) => (
								<Skeleton
									key={`compute-key-${index.toString()}`}
									height={60}
								/>
							))
						: computeProfile?.map((compute, _index) => (
							<ListItemButton
								key={compute?.display_name}
								sx={{ pl: 1, borderRadius: 1 }}
								selected={selectedProfileObj?.slug === compute?.slug}
								onClick={() => handleSelectComputeInstance(compute)}
								cy-data="compute-list-item"
							>
								<ListItemIcon>
									<img
										src={
											compute?.display_name.includes('GPU')
												? quantum8
												: quantum1
										}
										width={40}
										height={40}
										alt="qImg"
										style={{ objectFit: 'contain' }}
									/>
								</ListItemIcon>
								<ListItemText
									primaryTypographyProps={{ fontSize: 14 }}
									primary={compute?.display_name}
								/>
							</ListItemButton>
						  ))}
				</List>
			</Box>
			<Box
				id="compute-instance-actions"
				display="flex"
				justifyContent="flex-end"
				sx={{}}
			>
				<Button
					variant="slide"
					sx={(theme) => ({
						borderRadius: '100vw',
						fontFamily: theme.typography.fontFamily,
						fontWeight: 900,
						fontSize: 14
					})}
					onClick={handleLaunchLab}
					disabled={isSubmitting}
					cy-data="compute-list-launch-lab-btn"
				>
					Launch Lab &gt;
				</Button>
			</Box>
		</Stack>
	);
}

export default ComputeInstance;
