import { createTheme } from '@mui/material';
import { MUIComponents } from './component';
import { darkThemePalette, lightThemePalette } from './palette';
import { MUITypography } from './typography';

export const qbraidLightTheme = {
	palette: lightThemePalette,
	typography: MUITypography,
	components: MUIComponents,
	shadows: [
		...createTheme().shadows.slice(0, 24),
		'0 20px 25px -5px rgba(0, 0, 0, .1), 0 8px 10px -6px rgba(0, 0, 0, .1)'
	]
};
export const qbraidDarkTheme = {
	palette: darkThemePalette,
	typography: MUITypography,
	components: MUIComponents,
};
