import {
	FETCH_PRICES_LOADING,
	FETCH_PRICES_FAILED,
	FETCH_PRICES_SUCCESS,
	CREATE_SESSION_FAILED,
	CREATE_SESSION_LOADING,
	CREATE_SESSION_SUCCESS,
	GET_USER_CREDITS_FAILED,
	GET_USER_CREDITS_LOADING,
	GET_USER_CREDITS_SUCCESS,
	GET_USER_BILLING_FAILED,
	GET_USER_BILLING_LOADING,
	GET_USER_BILLING_SUCCESS,
	TRANSFER_CREDIT_SUCCESS,
	TRANSFER_CREDIT_FAILED,
	TRANSFER_CREDIT_RESET
} from '../Types/billingTypes';

// Subs are subscriptions
const initialState = {
	isLoading: false,
	creditsData: [],
	subData: [],
	academicSubData: [],
	showMessage: false,
	responseData: null,
	transferCredit: { success: false, msg: '' },
	success: false,
	link: null,
	qbraidCredits: 0,
	billing: null,
	stripe: null
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_PRICES_LOADING: {
			return {
				...state,
				isLoading: true
			};
		}
		case FETCH_PRICES_SUCCESS: {
			return {
				...state,
				isLoading: false,
				creditsData: action.payload.credits,
				subData: action.payload.subscriptions,
				academicSubData: action.payload.academicSubscriptions
			};
		}
		case FETCH_PRICES_FAILED: {
			return {
				...state,
				isLoading: false,
				creditsData: []
			};
		}
		case CREATE_SESSION_LOADING: {
			return {
				...state,
				isLoading: true,
				showMessage: false
			};
		}
		case CREATE_SESSION_SUCCESS: {
			return {
				...state,
				isLoading: false,
				showMessage: true,
				message: action.payload.message,
				responseData: action.payload
			};
		}
		case CREATE_SESSION_FAILED: {
			return {
				...state,
				isLoading: false,
				showMessage: true,
				responseData: action.payload
			};
		}
		case GET_USER_CREDITS_LOADING: {
			return {
				...state,
				isLoading: true
			};
		}
		case GET_USER_CREDITS_SUCCESS: {
			return {
				...state,
				isLoading: false,
				success: true,
				qbraidCredits: Math.round(action.payload?.qbraidCredits) || 0
			};
		}
		case GET_USER_CREDITS_FAILED: {
			return {
				...state,
				isLoading: false,
				success: false,
				qbraidCredits: 0
			};
		}
		case GET_USER_BILLING_LOADING: {
			return {
				...state,
				isLoading: true
			};
		}
		case GET_USER_BILLING_SUCCESS: {
			return {
				...state,
				isLoading: false,
				success: true,
				billing: action.payload.billing,
				stripe: action.payload.stripe,
				qbraidCredits: Math.round(action.payload.qbraidCredits) || 0
			};
		}
		case GET_USER_BILLING_FAILED: {
			return {
				...state,
				isLoading: false,
				success: false,
				billing: null,
				stripe: null,
				qbraidCredits: 0
			};
		}
		case TRANSFER_CREDIT_SUCCESS: {
			return {
				...state,
				transferCredit: { success: true, msg: action.payload }
			};
		}
		case TRANSFER_CREDIT_FAILED: {
			return {
				...state,
				transferCredit: { success: false, msg: action.payload }
			};
		}
		case TRANSFER_CREDIT_RESET: {
			return {
				...state,
				transferCredit: { success: false, msg: '' }
			};
		}
		default:
			return {
				...state
			};
	}
}
