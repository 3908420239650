/* eslint-disable no-else-return */
import jwtDecode from 'jwt-decode';
import { ACCOUNT_URL } from './axiosClient';

export const calcDate = (date1, date2) => {
	/*
	 * calcDate() : Calculates the difference between two dates
	 * @date1 : "First Date in the format MM-DD-YYYY"
	 * @date2 : "Second Date in the format MM-DD-YYYY"
	 * return : Object with time difference
	 */

	// Create date instances
	const dtDate1 = new Date(date1);
	const dtDate2 = new Date(date2);

	// Get the timestamps
	const date1TimeStamp = dtDate1.getTime();
	const date2TimeStamp = dtDate2.getTime();

	// Calculate the absolute difference in milliseconds
	const diffInMillis = Math.abs(date1TimeStamp - date2TimeStamp);

	// Calculate time components
	const totalSeconds = Math.floor(diffInMillis / 1000);
	const totalMinutes = Math.floor(totalSeconds / 60);
	const totalHours = Math.floor(totalMinutes / 60);
	const totalDays = Math.floor(totalHours / 24);
	const totalWeeks = Math.floor(totalDays / 7);
	const totalMonths = Math.floor(totalDays / 30.417); // Approximate month length
	const totalYears = Math.floor(totalDays / 365); // Approximate year length

	// Logic to determine the result
	if (totalYears > 1) {
		return {
			totalYears: totalYears,
			result: `${totalYears} ${totalYears === 1 ? 'year' : 'years'}`
		};
	} else if (totalYears === 1) {
		return {
			totalYears: totalYears,
			result: '1 year'
		};
	} else if (totalMonths > 0) {
		return {
			totalMonths: totalMonths,
			totalWeeks: totalWeeks,
			result: `${totalMonths} ${totalMonths === 1 ? 'month' : 'months'} ${
				totalWeeks > 0 ? `${totalWeeks} week${totalWeeks > 1 ? 's' : ''}` : ''
			}`.trim()
		};
	} else if (totalDays > 0) {
		return {
			totalWeeks: totalWeeks,
			totalDays: totalDays,
			result: `${
				totalWeeks > 0 ? `${totalWeeks} week${totalWeeks > 1 ? 's' : ''} ` : ''
			}${totalDays} ${totalDays === 1 ? 'day' : 'days'}`.trim()
		};
	} else if (totalHours > 0) {
		return {
			totalHours: totalHours,
			result: `${totalHours} ${totalHours === 1 ? 'hour' : 'hours'}`
		};
	} else {
		// Return hourly information if less than an hour
		return {
			totalHours: 0,
			result: 'Less than an hour'
		};
	}
};

export const getPartnerName = (pathname) => pathname.split('/')[4];

export const getAccountURL = (pathname) => {
	if (pathname.includes('xanadu')) {
		return `${ACCOUNT_URL}auth/login/partner/${getPartnerName(pathname)}`;
	}
	return `${ACCOUNT_URL}oauth`;
};

export const getFilePathFromHashedPath = async (hashedPath) => {
	try {
		const decodedObject = jwtDecode(hashedPath);
		return decodedObject ? decodedObject.filePath : null;
	} catch (error) {
		return null;
	}
};
