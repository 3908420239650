import {
	DEVICES_DATA_FAIL,
	DEVICES_DATA_LOADING,
	DEVICES_DATA_SUCCESS
} from '../Types/quantumDeviceTypes';

const initialState = {
	isLoading: false,
	quantumDevicesArray: [],
	success: true
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case DEVICES_DATA_LOADING:
			return {
				...state,
				isLoading: true
			};
		case DEVICES_DATA_SUCCESS:
			return {
				...state,
				isLoading: false,
				quantumDevicesArray: action.payload,
				success: true
			};
		case DEVICES_DATA_FAIL:
			return {
				...state,
				isLoading: false,
				success: false
			};
		default:
			return state;
	}
}
