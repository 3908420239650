import { combineReducers } from 'redux';
import blogsReducer from './blogsReducer';
import userReducer from './userReducer';
import papersReducer from './papersReducer';
import coursesReducer from './coursesReducer';
import billingReducer from './billingReducer';
import userJobsReducer from './userJobsReducer';
import subscriptionReducers from './subscriptionReducers';
import filesReducer from './filesReducer';
import lemReducer from './loadingErrMsgReducer';
import filesDrawerReducer from './filesDrawerReducer';
import organizationReducer from './organizationReducer';
import themeReducer from './themeReducer';
import computeReducer from './computeReducer';
import quantumDeviceReducer from './quantumDeviceReducer';
import docsTutorialReducer from './docsTutorialReducer'
import maintenanceReducer from "./maintenanceReducer";

const RootReducer = combineReducers({
	blogsReducer,
	coursesReducer,
	userJobsReducer,
	userReducer,
	papersReducer,
	billingReducer,
	subscriptionReducers,
	filesReducer,
	lemReducer,
	filesDrawerReducer,
	organizationReducer,
	themeReducer,
	computeReducer,
	quantumDeviceReducer,
	docsTutorialReducer,
	maintenanceReducer
});
export default RootReducer;
