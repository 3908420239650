/* eslint-disable no-else-return */
import jwtDecode from 'jwt-decode';
import { ACCOUNT_URL } from './axiosClient';

export const calcDate = (date1, date2) => {
	/*
	 * calcDate() : Calculates the difference between two dates
	 * @date1 : "First Date in the format MM-DD-YYYY"
	 * @date2 : "Second Date in the format MM-DD-YYYY"
	 * return : Object with time difference
	 */

	// Create date instances
	const dtDate1 = new Date(date1);
	const dtDate2 = new Date(date2);

	// Get the timestamps
	const date1TimeStamp = dtDate1.getTime();
	const date2TimeStamp = dtDate2.getTime();

	// Calculate the absolute difference in milliseconds
	const diffInMillis = Math.abs(date1TimeStamp - date2TimeStamp);

	// Calculate time components
	const totalSeconds = Math.floor(diffInMillis / 1000);
	const totalMinutes = Math.floor(totalSeconds / 60);
	const totalHours = Math.floor(totalMinutes / 60);
	const totalDays = Math.floor(totalHours / 24);
	const totalWeeks = Math.floor(totalDays / 7);
	const totalMonths = Math.floor(totalDays / 30.417); // Approximate month length
	const totalYears = Math.floor(totalDays / 365); // Approximate year length

	// Logic to determine the result
	if (totalYears > 1) {
		return {
			totalYears: totalYears,
			result: `${totalYears} ${totalYears === 1 ? 'year' : 'years'}`
		};
	} else if (totalYears === 1) {
		return {
			totalYears: totalYears,
			result: '1 year'
		};
	} else if (totalMonths > 0) {
		return {
			totalMonths: totalMonths,
			totalWeeks: totalWeeks,
			result: `${totalMonths} ${totalMonths === 1 ? 'month' : 'months'} ${
				totalWeeks > 0 ? `${totalWeeks} week${totalWeeks > 1 ? 's' : ''}` : ''
			}`.trim()
		};
	} else if (totalDays > 0) {
		return {
			totalWeeks: totalWeeks,
			totalDays: totalDays,
			result: `${
				totalWeeks > 0 ? `${totalWeeks} week${totalWeeks > 1 ? 's' : ''} ` : ''
			}${totalDays} ${totalDays === 1 ? 'day' : 'days'}`.trim()
		};
	} else if (totalHours > 0) {
		return {
			totalHours: totalHours,
			result: `${totalHours} ${totalHours === 1 ? 'hour' : 'hours'}`
		};
	} else {
		// Return hourly information if less than an hour
		return {
			totalHours: 0,
			result: 'Less than an hour'
		};
	}
};

export const getPartnerName = (pathname) => pathname.split('/')[4];

export const getAccountURL = (pathname) => {
	if (pathname.includes('xanadu')) {
		return `${ACCOUNT_URL}auth/login/partner/${getPartnerName(pathname)}`;
	}
	return `${ACCOUNT_URL}oauth`;
};

export const getFilePathFromHashedPath = async (hashedPath) => {
	try {
		const decodedObject = jwtDecode(hashedPath);
		return decodedObject ? decodedObject.filePath : null;
	} catch (error) {
		return null;
	}
};
/**
 * Constructs a new URL by appending a specified path after "/lab/tree/" in the current URL.
 * Useful for applications using "/lab" and "/tree/" routing patterns.
 *
 * @param newTreePath The path to append after "/lab/tree/". Does not include "/tree/".
 * @returns The new URL string if "/lab" is found, otherwise logs an error and returns null.
 */
export function localRedirectUrl(newTreePath) {
	const basePath = '/lab';
	const newPath = `/tree/${newTreePath}`;

	const currentUrl= window.location.href;
	const labIndex = currentUrl.lastIndexOf(basePath);

	if (labIndex !== -1) {
		return currentUrl.substring(0, labIndex + basePath.length) + newPath;
	} 
	console.log('Error: "/lab" not found in current URL');
	return null;
	
}

/**
 * Processes a given link to redirect .ipynb files not starting with "http" through
 * a local redirection URL determined by `localRedirectUrl`. This is particularly
 * useful for handling local or relative paths to notebooks that need to be served
 * through a specific routing pattern.
 *
 * @param link The original link to a resource.
 * @returns A processed link. If the original link is for a .ipynb file not starting
 *          with "http", it's processed through `localRedirectUrl`. Otherwise, the
 *          original link is returned unchanged.
 */
export function processLink(link){
	if (!link.startsWith('http') && link.endsWith('.ipynb')) {
		const processedLink = localRedirectUrl(link);
		if (processedLink) {
			return processedLink;
		} 
		console.error('Failed to process the link through localRedirectUrl');
		
	}
	// Return the original or processed link
	return link;
}
