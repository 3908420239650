import { LEM_SNACK_OPEN, LEM_SNACK_CLOSE } from '../Types/loadingErrMsgTypes';

const initialState = {
	openSnack: false,
	snackMessage: '',
	snackColor: true
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case LEM_SNACK_OPEN:
			return {
				...state,
				openSnack: true,
				message: action.payload,
				snackColor: false
			};
		case LEM_SNACK_CLOSE:
			return {
				...state,
				openSnack: false,
				message: '',
				snackColor: true
			};
		default:
			return state;
	}
}
