import QBraidShortLogo from '../../assets/images/qbraid.png';
import QBraidFullDarkLogo from '../../assets/images/qbraid_white_logo.png';
import QBraidFullLightLogo from '../../assets/images/qbraid_blackLogo.png';
import IAMIcon from '../../assets/images/IAM_icon.png';
import planIcon from '../../assets/images/file-user-icon.svg';
import qJobsIcon from '../../assets/images/QuantumJobsIcon.svg';
import prize from '../../assets/images/winning_icon_alt.svg';
import learn from '../../assets/images/open-book-icon.svg';
import tutorial from '../../assets/images/TutorialIcon.svg';
import quantumDevice from '../../assets/images/quantumDevice.svg';
import quantumComposer from '../../assets/images/quantumComposer.svg';
import BannerImage from '../../assets/images/qbraid-banner.png';
import FavIcon from '../../assets/images/favicon.png';
import profileCover from '../../assets/images/profile-cover1.png';
import qbraidOrgDark from '../../assets/images/qbraid_org_dark.png';
import qbraidOrgLight from '../../assets/images/qbraid_org_light.png';

/**
 * DO NOT EDIT THIS CONFIG
 * Configuration options for the qbraid environment.
 * @type {Object}
 */
export const qbraidConfig = {
	name: 'qbraid',
	live: /account.qbraid/,
	staging: /account-staging.qbraid/,
	account: 'https://account.qbraid.com/',
	accountStaging: 'https://account-staging.qbraid.com/',
	api: 'https://api.qbraid.com/',
	apiStaging: 'https://api-staging-1.qbraid.com/',
	lab: 'https://lab.qbraid.com',
	labStaging: 'https://lab-staging.qbraid.com',
	qbook: 'https://qbook.qbraid.com',
	qbookStaging: 'https://qbook-staging.qbraid.com'
};

// qBraid Content Management Object
export const qbraid = {
	title: 'qBraid',
	name: 'qbraid',
	domain: '.qbraid.com',
	description:
		'qBraid empowers the quantum community to learn, build, and share quantum software',
	banner: BannerImage,
	favIcon: FavIcon,
	profileCover: profileCover,
	userPoolId: 'us-east-1_7hq9OmpnT',
	userPoolClientId: '70fo00fpob1sd133m98k7b0jan',
	logo_simple: {
		logo: QBraidShortLogo,
		logo_dark: QBraidShortLogo
	},
	logo_full: {
		logo: QBraidFullLightLogo,
		logo_dark: QBraidFullDarkLogo
	},
	loginModal: {
		logo: { image: QBraidShortLogo, width: 125, height: 125 },
		description:
			'The entire quantum ecosystem, all in one place. Start coding quantum today. No setup required.'
	},
	header: {
		navigation: [
			{
				icon: planIcon,
				name: 'Your Plan',
				link: '/'
			},
			{
				icon: IAMIcon,
				name: 'Organizations',
				link: '/organization'
			},
			{
				icon: quantumDevice,
				name: 'Devices',
				link: '/quantumdevices'
			},
			{
				icon: qJobsIcon,
				name: 'Quantum Jobs',
				link: '/quantumjobs'
			},
			{
				icon: prize,
				name: 'Hackathons',
				link: '/hackathons'
			},
			{
				icon: learn,
				name: 'Courses',
				link: '/courses'
			},
			{
				icon: tutorial,
				name: 'Tutorials',
				link: '/tutorials'
			},
			{
				icon: quantumComposer,
				name: 'Composer',
				link: '/quantumcomposer'
			}
		],
		extraNavigation: []
	},
	dashboard: {
		title: 'How to use your qBraid Subscription',
		description:
			'Use your qBraid subscription to access a robust suite of compute resources, encompassing scalable CPUs, GPUs, and QPUs.',
		button: 'Learn more',
		button_link: 'https://docs.qbraid.com/home/pricing',
		video_id: 'iqYmB7H2R2E',
		video_title: 'Using TensorFlow Quantum on qBraid',
		image: null
	},
	organization: {
		noOrgLogo: {
			logo: qbraidOrgLight,
			logoDark: qbraidOrgDark
		},
		title: 'qBraid Organization',
		description:
			"qBraid's identity and access management system provides an easy way to provision quantum computing resources to multiple users within a single organization.",
		button: 'Learn more',
		button_link: 'https://docs.qbraid.com/home/account'
	},
	quantum_devices: {
		title: 'Quantum Devices',
		description:
			'qBraid provides access to a diverse range of quantum devices, including Quantum Processing Units (QPUs) from IonQ, Rigetti, and others, as well as high-performance quantum simulators. Users can easily run their quantum circuits on various hardware through the qBraid platform, facilitating the development and adoption of quantum computing technologies.',
		button: 'Learn more',
		button_link: 'https://docs.qbraid.com/home/pricing'
	},
	quantum_jobs: {
		title: 'Quantum Jobs',
		description:
			'qBraid Quantum Jobs grants users direct access to QPU devices from IonQ, IQM, Oxford Quantum Circuits, QuEra, and Rigetti, as well as on-demand simulators from AWS and qBraid with no additional access keys required.',
		button: 'Learn more',
		button_link: 'https://docs.qbraid.com/home/pricing'
	},
	hackathons: {
		title: 'Hackathons',
		description:
			'From universites to enterprises, qBraid is a trusted platform for hackathons, providing a single gateway to quantum computing resources and a streamlined event management process.',
		button: 'Contact Us',
		button_link: 'https://www.qbraid.com/contact'
	},
	courses: {
		title: 'Forge Your Quantum Future',
		description:
			'Grasp the complexities of quantum computing with our complimentary course, backed by the White House Q12 initiative, covering the mathematical, computational, and physical aspects of the field.'
	},
	tutorials: {
		title: 'Browse Tutorials & Resources:',
		description: 'Begin your journey into quantum computing using qBraid'
	},
	composer: {
		title: 'Quirk: Compose your quantum circuits',
		description:
			'A drag-and-drop quantum circuit simulator created by Craig Gidney that runs in your browser. A tool for exploring and understanding small quantum circuits.'
	},
	cookies_consent: [
		{
			id: 'analytics_storage',
			name: 'Analytics Cookies',
			description:
				'Analytics cookies collect data on user interactions with the website. This information helps website owners understand how visitors use their site, identify trends, and improve site performance and user experience.',
			default_value: 'denied'
		},
		{
			id: 'personalization_storage',
			name: 'Personalization cookies',
			description:
				'Personalization cookies enhance your web experience by remembering your preferences and tailoring content to your interests. They enable targeted advertising and customized content recommendations based on your browsing behavior. These cookies help websites deliver a more relevant and personalized experience for each user.',
			default_value: 'denied'
		}
	]
};
