import {
	NO_USER_SUBSCRIPTION,
	FETCH_USER_SUBSCRIPTION_FAIL,
	FETCH_USER_SUBSCRIPTION_LOADING,
	FETCH_USER_SUBSCRIPTION_SUCCESS,
	FETCH_CARD_INFO_FAIL,
	FETCH_CARD_INFO_LOADING,
	FETCH_CARD_INFO_SUCCESS,
	ADD_NEW_CARD,
	FETCH_USER_PREV_SUBS_SUCCESS,
	FETCH_USER_PREV_SUBS_LOADING,
	FETCH_USER_PREV_SUBS_FAILURE,
	NO_USER_PREV_SUBSCRIPTION,
	FETCH_USER_TRANSACTION,
	FETCH_USER_TRANSACTION_LOADING,
	FETCH_USER_TRANSACTION_ERROR
} from '../Types/subscriptionTypes';

const initialState = {
	isLoading: false,
	isCardLoading: false,
	subscriptionData: [], // stores the payment intent (PI data)
	prevSubscriptionInfo: [], // stores the subscription data (standard, pro, free)
	isTransactionLoading: false,
	transactionError: false,
	transactions: [],
	pagination: {
		currentPage: 0,
		totalPages: 0,
		totalCount: 0,
		resultsPerPage: 10
	},
	noUserSub: false,
	noPrevSub: false,
	showError: false,
	cardInfo: null,
	addCard: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_USER_PREV_SUBS_SUCCESS: {
			return {
				...state,
				prevSubscriptionInfo: action.payload,
				isLoading: false,
				noUserSub: false,
				showError: false
			};
		}
		case FETCH_USER_PREV_SUBS_LOADING: {
			return {
				...state,
				isLoading: true,
				noUserSub: false,
				showError: false
			};
		}
		case FETCH_USER_PREV_SUBS_FAILURE: {
			return {
				...state,
				isLoading: false,
				showError: true
			};
		}
		case FETCH_USER_SUBSCRIPTION_LOADING: {
			return {
				...state,
				isLoading: true,
				subscriptionData: [],
				noUserSub: false,
				showError: false
			};
		}
		case FETCH_USER_SUBSCRIPTION_SUCCESS: {
			return {
				...state,
				isLoading: false,
				subscriptionData: action.payload,
				noUserSub: false,
				showError: false
			};
		}
		case FETCH_USER_SUBSCRIPTION_FAIL: {
			return {
				...state,
				isLoading: false,
				subscriptionData: [],
				noUserSub: false,
				showError: true
			};
		}
		case NO_USER_SUBSCRIPTION: {
			return {
				...state,
				isLoading: false,
				subscriptionData: action.payload,
				noUserSub: true
			};
		}
		case NO_USER_PREV_SUBSCRIPTION: {
			return {
				...state,
				isLoading: false,
				prevSubscriptionInfo: action.payload,
				noPrevSub: true
			};
		}
		case FETCH_CARD_INFO_LOADING: {
			return {
				...state,
				isCardLoading: true
			};
		}
		case FETCH_CARD_INFO_SUCCESS: {
			return {
				...state,
				isCardLoading: false,
				cardInfo: action.payload
			};
		}
		case FETCH_CARD_INFO_FAIL: {
			return {
				...state,
				isCardLoading: false,
				cardInfo: null
			};
		}
		case ADD_NEW_CARD: {
			return {
				...state,
				isCardLoading: false,
				cardInfo: null,
				addCard: true
			};
		}
		case FETCH_USER_TRANSACTION: {
			return {
				...state,
				isTransactionLoading: false,
				transactionError: false,
				transactions: action.payload.transactions,
				pagination: action.payload.pagination
			};
		}
		case FETCH_USER_TRANSACTION_LOADING: {
			return {
				...state,
				isTransactionLoading: true
			};
		}
		case FETCH_USER_TRANSACTION_ERROR: {
			return {
				...state,
				isTransactionLoading: false,
				transactionError: true
			};
		}
		default:
			return {
				...state
			};
	}
}
