import {
	PUBLIC_COURSES_LOADING,
	PUBLIC_COURSES_SUCCESS,
	PUBLIC_COURSES_FAIL,
	USERS_COURSES_LOADING,
	USERS_COURSES_SUCCESS,
	USERS_COURSES_FAIL,
	REGISTER_FOR_COURSES_LOADING,
	REGISTER_FOR_COURSES_SUCCESS,
	REGISTER_FOR_COURSES_FAIL
} from '../Types/coursesTypes';

const initialState = {
	isLoading: false,
	publicCoursesArray: [],
	usersCoursesArray: [],
	success: true
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case PUBLIC_COURSES_LOADING:
			return {
				...state,
				isLoading: true
			};
		case PUBLIC_COURSES_SUCCESS:
			return {
				...state,
				isLoading: false,
				publicCoursesArray: action.payload,
				success: true
			};
		case PUBLIC_COURSES_FAIL:
			return {
				...state,
				isLoading: false,
				success: false
			};
		case USERS_COURSES_LOADING:
			return {
				...state,
				isLoading: true
			};
		case USERS_COURSES_SUCCESS:
			return {
				...state,
				isLoading: false,
				usersCoursesArray: action?.payload || [],
				success: true
			};
		case USERS_COURSES_FAIL:
			return {
				...state,
				isLoading: false,
				usersCoursesArray: [],
				success: false
			};
		case REGISTER_FOR_COURSES_LOADING:
			return {
				...state,
				isLoading: true
			};
		case REGISTER_FOR_COURSES_SUCCESS:
			return {
				isLoading: false,
				success: true
			};
		case REGISTER_FOR_COURSES_FAIL:
			return {
				isLoading: false,
				success: false
			};
		default:
			return state;
	}
}
