import React from 'react';
import './index.css';
import { Paper } from '@mui/material';

function CustomCard({
	children,
	variant,
	bgColor,
	isHoverable,
	animate,
	customStyles,
	customCSS,
	cyData
}) {
	return (
		<Paper
			className={`custom-card custom-${variant} ${
				isHoverable && 'custom-hover'
			} ${animate} ${customCSS}`}
			elevation={variant === 'solid' ? 4 : 0}
			sx={(theme) => ({
				borderRadius: '12px',
				bgcolor: variant === 'transparent' ? 'transparent' : bgColor,
				borderWidth: '1px',
				borderStyle: 'solid',
				borderColor:
					variant === 'transparent' ? 'transparent' : theme.palette.divider,
				...customStyles
			})}
			cy-data={cyData}
		>
			{children}
		</Paper>
	);
}

export default CustomCard;

CustomCard.defaultProps = {
	variant: 'solid',
	bgColor: '',
	isHoverable: true,
	customStyles: '',
	animate: '',
	customCSS: '',
	cyData: ''
};
