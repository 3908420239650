export const BLOGS_LOADING = 'BLOGS_LOADING';
export const BLOGS_SUCCESS = 'BLOGS_SUCCESS';
export const BLOGS_FAIL = 'BLOGS_FAIL';
export const BLOGS_LIKE_BYID_LOADING = 'BLOGS_LIKE_BYID_LOADING';
export const BLOGS_LIKE_BYID_SUCCESS = 'BLOGS_LIKE_BYID_SUCCESS';
export const BLOGS_LIKE_BYID_FAIL = 'BLOGS_LIKE_BYID_FAIL';
export const BLOGS_BOOKMARK_BYID_LOADING = 'BLOGS_BOOKMARK_BYID_LOADING';
export const BLOGS_BOOKMARK_BYID_SUCCESS = 'BLOGS_BOOKMARK_BYID_SUCCESS';
export const BLOGS_BOOKMARK_BYID_FAIL = 'BLOGS_BOOKMARK_BYID_FAIL';
export const BLOGS_DELETE_BYID_LOADING = 'BLOGS_DELETE_BYID_LOADING';
export const BLOGS_DELETE_BYID_SUCCESS = 'BLOGS_DELETE_BYID_SUCCESS';
export const BLOGS_DELETE_BYID_FAIL = 'BLOGS_DELETE_BYID_FAIL';
