import { Typography } from '@mui/material';
import React from 'react';
import { ThreeCircles } from 'react-loader-spinner';

function Loading() {
	return (
		<div className="outer_fall_back_div">
			<div className="react-loader">
				<ThreeCircles
					height="80"
					width="80"
					color={
						window.location.pathname.includes('/login/partner/xanadu')
							? '#173583'
							: 'var(--brand-color-1)'
					}
					ariaLabel="grid-loading"
					radius="12.5"
					wrapperClass=""
					visible
				/>
				<Typography
					fontSize={36}
					textAlign="center"
					className="react-loader-text"
					cy-data="loader-text"
				>
					Loading...
				</Typography>
			</div>
		</div>
	);
}

export default Loading;
