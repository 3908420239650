import { axiosClient, email, LAB_URL } from '../../utils/axiosClient';
import {
	SET_COMPUTE_ARRAY,
	SET_SELECTED_COMPUTE_PROFILE_OBJECT
} from '../Types/computeTypes';

export const LoadComputeProfiles = () => async (dispatch) => {
	try {
		const response = await axiosClient().get(
			`/api/lab/compute/get-profiles/${email}`
		);
		if (response.status === 200) {
			dispatch(SetComputeArray(response.data));
			dispatch(SetSelectedComputeProfileObj(response.data[0]));
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.log(error);
	}
};

export const SetComputeArray = (payload) => ({
	type: SET_COMPUTE_ARRAY,
	payload
});

export const SetSelectedComputeProfileObj = (payload) => ({
	type: SET_SELECTED_COMPUTE_PROFILE_OBJECT,
	payload
});

export const postComputeLabtoken = async () => {
	const response = await axiosClient().post(`/api/lab/compute/tokens`, {
		labUrl: LAB_URL
	});
	return response;
};

export const startServer = async ({ token, slug }) => {
	const response = await axiosClient().post('/api/lab/compute/start/server', {
		labToken: token,
		labURL: LAB_URL,
		slug: slug
	});
	return response;
};
