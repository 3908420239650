import React from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import './index.css';
import CustomButton from '../button';

function DescriptionComponent({ headerText, subText, link }) {
	return (
		<Stack justifyContent="flex-start" height="100%" gap={1}>
			<Typography
				fontSize={28}
				fontWeight={700}
				className="dB-desc-heading dB-text-gradient"
			>
				{headerText}
			</Typography>
			<Typography className="dB-desc-desc">{subText}</Typography>
			<Link
				href={link.url}
				target="_blank"
				rel="noreferrer"
				color="inherit"
				sx={{
					textDecoration: 'none',
					width: 'fit-content',
					outline: 'transparent',
					mt: 2
				}}
			>
				<CustomButton
					variant="outlined"
					size="large"
					customCSS="dB-expand-btn"
					title="Open link in new window"
				>
					<span>{link?.linkName}</span>
					<OpenInNew fontSize="inherit" size="small" className="btn_icon" />
				</CustomButton>
			</Link>
		</Stack>
	);
}

export default DescriptionComponent;

DescriptionComponent.defaultProps = {
	headerText: 'pass value to prop:headerText',
	subText:
		'pass value to prop:subText, eg: <DescriptionComponent headerText="Lorem ipsum" subText="lorem ipsum jovel la es queeto"/>'
};
