/**
 * @fileoverview This file contains the code for the PublicHeadTags component.
 * It includes functionality for setting the head tags for the public pages of the application.
 *
 * The PublicHeadTags component includes the following features:
 * - Setting the title and description of the page based on the current domain
 * - Setting the viewport and theme color meta tags
 * - Setting the Twitter and Open Graph meta tags for social sharing
 * - Setting the favicon and banner image for the page
 *
 * @copyright (C) 2024 qBraid Development Team
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { currentDomain } from '../../utils/domainBasedInfo/currentDomain';

/**
 * The PublicHeadTags component sets the head tags for the public pages of the application.
 * @returns
 */
function PublicHeadTags() {
	return (
		<Helmet>
			<title>{currentDomain.title}</title>
			<meta name="description" content={currentDomain.description} />
			<meta name="viewport" content="width=device-width, initial-scale=1" />
			<meta name="theme-color" content="#000000" />

			<meta name="twitter:card" content="summary_large" />
			<meta name="twitter:site" content="@qbraid_official" />
			<meta name="twitter:creator" content="@qbraid_official" />
			<meta
				property="og:url"
				content={`https://account${currentDomain.domain}`}
			/>
			<meta property="og:title" content={currentDomain.title} />
			<meta property="og:description" content={currentDomain.description} />
			<meta property="og:image" content={currentDomain.banner} />
			<meta property="og:image:type" content="image/png" />
			<meta property="og:image:width" content="640" />
			<meta property="og:image:height" content="360" />
			<link rel="icon" href={currentDomain.favIcon} />
		</Helmet>
	);
}

export default PublicHeadTags;
