export const USER_LOGIN_LOADING = 'USER_LOGIN_LOADING';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT_LOADING = 'USER_LOGOUT_LOADING';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL';
export const USER_SIGNUP_LOADING = 'USER_SIGNUP_LOADING';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_FAIL = 'USER_SIGNUP_FAIL';
export const USER_LOGOUT_UPDATE_SUCCESS = 'USER_LOGOUT_UPDATE_SUCCESS';
export const USER_DATA_LOADING = 'USER_DATA_LOADING';
export const USER_DATA_SUCCESS = 'USER_DATA_SUCCESS';
export const USER_DATA_FAIL = 'USER_DATA_FAIL';
export const USER_EDIT_PROFILE_LOADING = 'USER_EDIT_PROFILE_LOADING';
export const USER_EDIT_PROFILE_SUCCESS = 'USER_EDIT_PROFILE_SUCCESS';
export const USER_EDIT_PROFILE_FAIL = 'USER_EDIT_PROFILE_FAIL';
export const USER_FORGOT_PASSWORD_LOADING = 'USER_FORGOT_PASSWORD_LOADING';
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS';
export const USER_FORGOT_PASSWORD_FAIL = 'USER_FORGOT_PASSWORD_FAIL';
export const USER_SUBMIT_NEW_PASSWORD_FAIL = 'USER_SUBMIT_NEW_PASSWORD_FAIL';
export const USER_SUBMIT_NEW_PASSWORD_LOADING =
	'USER_SUBMIT_NEW_PASSWORD_LOADING';
export const USER_SUBMIT_NEW_PASSWORD_SUCCESS =
	'USER_SUBMIT_NEW_PASSWORD_SUCCESS';
export const MARKETING_UPDATE_FAIL = 'MARKETING_UPDATE_FAIL';
export const MARKETING_UPDATE_LOADING = 'MARKETING_UPDATE_LOADING';
export const MARKETING_UPDATE_SUCCESS = 'MARKETING_UPDATE_SUCCESS';
export const REFRESH_API_SUCCESS = 'REFRESH_API_SUCCESS';
export const REFRESH_API_FAILURE = 'REFRESH_API_FAILURE';
export const REFRESH_API_LOADING = 'REFRESH_API_LOADING';
export const USER_COGNITO_STORE = 'USER_COGNITO_STORE';
export const USER_COGNITO_REMOVE = 'USER_COGNITO_REMOVE';
