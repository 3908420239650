import QuSteamLogo from '../../assets/images/quSteam_full_logo.png';
import QuSteamShortLogo from '../../assets/images/QuSTEAMLogo.jpg';
import QuSteamDashboardPicture from '../../assets/images/20190328_AwschalomLab_1174.jpg';
import canvasDark from '../../assets/images/canvas_for_dark.png';
import canvasLight from '../../assets/images/canvas_for_light.png';
import IAMIcon from '../../assets/images/IAM_icon.png';
import planIcon from '../../assets/images/file-user-icon.svg';
import qJobsIcon from '../../assets/images/QuantumJobsIcon.svg';
import prize from '../../assets/images/winning_icon_alt.svg';
import learn from '../../assets/images/open-book-icon.svg';
import tutorial from '../../assets/images/TutorialIcon.svg';
import QuSteamProfileBanner from '../../assets/images/qusteam_abstract_banner.jpg';
import QuSteamIAMDark from '../../assets/images/qusteam_iam_role_dark.png';
import QuSteamIAMLight from '../../assets/images/qusteam_iam_role_light.png';
import quantumDevice from '../../assets/images/quantumDevice.svg';
import quantumComposer from '../../assets/images/quantumComposer.svg';

/**
 * DO NOT EDIT THIS CONFIG
 * Configuration options for the qusteam environment.
 * @type {Object}
 */
export const qusteamConfig = {
	name: 'qusteam',
	live: /account.qusteam/,
	staging: /account.qusteam/,
	account: 'https://account.qusteam.org/',
	accountStaging: 'https://account.qusteam.org/',
	api: 'https://api.qbraid.com/',
	apiStaging: 'https://api-staging-1.qbraid.com/',
	lab: 'https://lab.qusteam.org',
	labStaging: 'https://lab.qusteam.org',
	qbook: 'https://qbook.qusteam.org',
	qbookStaging: 'https://qbook.qusteam.org'
};

// qusteam Content Management Object
export const qusteam = {
	title: 'QuSTEAM',
	name: 'qusteam',
	domain: '.qusteam.org',
	description:
		'QuSTEAM empowers the quantum community to learn, build, and share quantum software',
	banner: QuSteamLogo,
	favIcon: QuSteamShortLogo,
	profileCover: QuSteamProfileBanner,
	userPoolId: 'us-east-1_dpl4rADNO',
	userPoolClientId: '4igp804jk3cabvprcn14i8j8kf',
	logo_simple: {
		logo: QuSteamShortLogo,
		logo_dark: QuSteamShortLogo
	},
	logo_full: {
		logo: QuSteamLogo,
		logo_dark: QuSteamLogo
	},
	loginModal: {
		logo: { image: QuSteamLogo, width: 275, height: 132 },
		description:
			'The entire quantum ecosystem, all in one place. Start coding quantum today. No setup required.'
	},
	header: {
		navigation: [
			{
				icon: planIcon,
				icon_dark: '',
				name: 'Your Plan',
				link: '/'
			},
			{
				icon: IAMIcon,
				icon_dark: '',
				name: 'Organizations',
				link: '/organization'
			},
			{
				icon: quantumDevice,
				name: 'Devices',
				link: '/quantumdevices'
			},
			{
				icon: qJobsIcon,
				icon_dark: '',
				name: 'Quantum Jobs',
				link: '/quantumjobs'
			},
			{
				icon: prize,
				icon_dark: '',
				name: 'Hackathons',
				link: '/hackathons'
			},
			{
				icon: learn,
				icon_dark: '',
				name: 'Courses',
				link: '/courses'
			},
			{
				icon: tutorial,
				icon_dark: '',
				name: 'Tutorials',
				link: '/tutorials'
			},
			{
				icon: quantumComposer,
				name: 'Composer',
				link: '/quantumcomposer'
			}
		],
		extraNavigation: [
			{
				icon: canvasLight,
				icon_dark: canvasDark,
				name: 'Canvas',
				link: 'http://qusteam.instructure.com/login/openid_connect'
			}
		]
	},
	dashboard: {
		title: "QuSTEAM Member Instructor's qBraid Subscription",
		description:
			"As part of your institution's QuSTEAM subscription, instructors can use Canvas or qBraid to distribute content and QPU credits to students. Use either platform's Course Builder to customize course modules.",
		button: 'Learn more',
		button_link: 'https://qusteam.org/',
		video_id: null,
		video_title: null,
		image: QuSteamDashboardPicture
	},
	organization: {
		noOrgLogo: {
			logo: QuSteamIAMLight,
			logoDark: QuSteamIAMDark
		},
		title: 'Organizations',
		description:
			'QuSTEAMs identity and access management provides an easy way to provision and manage quantum computing resources across multiple users.',
		button: 'Learn more',
		button_link: 'https://docs.qbraid.com/home/account'
	},
	quantum_devices: {
		title: 'Quantum Devices',
		description:
			'qBraid provides access to a diverse range of quantum devices, including Quantum Processing Units (QPUs) from IonQ, Rigetti, and others, as well as high-performance quantum simulators. Users can easily run their quantum circuits on various hardware through the qBraid platform, facilitating the development and adoption of quantum computing technologies.',
		button: 'Learn more',
		button_link: 'https://docs.qbraid.com/home/pricing'
	},
	quantum_jobs: {
		title: 'Quantum Jobs',
		description:
			'qBraid Quantum Jobs give QuSTEAM Lab users direct access to QPU devices from IonQ, IQM, Oxford Quantum Circuits, QuEra, and Rigetti, as well as on-demand simulators from AWS and qBraid with no additional access keys required.',
		button: 'Learn more',
		button_link: 'https://docs.qbraid.com/home/pricing'
	},
	hackathons: {
		title: 'Hackathons',
		description:
			'From universites to enterprises, QuSTEAM is a trusted platform for hackathons, providing a single gateway to quantum computing resources and a streamlined event management process.',
		button: 'Contact Us',
		button_link: 'https://www.qbraid.com/contact'
	},
	courses: {
		title: 'Forge Your Quantum Future',
		description:
			'Grasp the complexities of quantum computing with our complimentary course, backed by the White House Q12 initiative, covering the mathematical, computational, and physical aspects of the field.'
	},
	tutorials: {
		title: 'Getting Started',
		description: 'Begin your journey into quantum computing using QuSTEAM'
	},
	composer: {
		title: 'Quirk: Compose your quantum circuits',
		description:
			'A drag-and-drop quantum circuit simulator created by Craig Gidney that runs in your browser. A tool for exploring and understanding small quantum circuits.'
	},
	cookies_consent: [
		{
			id: 'analytics_storage',
			name: 'Analytics Cookies',
			description:
				'Analytics cookies collect data on user interactions with the website. This information helps website owners understand how visitors use their site, identify trends, and improve site performance and user experience.',
			default_value: 'denied'
		},
		{
			id: 'personalization_storage',
			name: 'Personalization cookies',
			description:
				'Personalization cookies enhance your web experience by remembering your preferences and tailoring content to your interests. They enable targeted advertising and customized content recommendations based on your browsing behavior. These cookies help websites deliver a more relevant and personalized experience for each user.',
			default_value: 'denied'
		}
	]
};
