import {
	DEVICES_DATA_FAIL,
	DEVICES_DATA_LOADING,
	DEVICES_DATA_SUCCESS,
	PROVIDER_DATA_FAIL,
	PROVIDER_DATA_LOADING,
	PROVIDER_DATA_SUCCESS
} from '../Types/quantumDeviceTypes';

const initialState = {
	isLoading: false,
	quantumDevicesArray: [],
	success: true,
	quantumProvidersArray: [],
	providerStatus: 'loading' // or 'success', 'error',
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case DEVICES_DATA_LOADING:
			return {
				...state,
				isLoading: true
			};
		case DEVICES_DATA_SUCCESS:
			return {
				...state,
				isLoading: false,
				quantumDevicesArray: action.payload,
				success: true
			};
		case DEVICES_DATA_FAIL:
			return {
				...state,
				isLoading: false,
				success: false
			};
		case PROVIDER_DATA_LOADING:
			return {
				...state,
				providerStatus: 'loading'
			};
		case PROVIDER_DATA_SUCCESS:
			return {
				...state,
				providerStatus: 'success',
				quantumProvidersArray: action.payload
			};
		case PROVIDER_DATA_FAIL:
			return {
				...state,
				providerStatus: 'error'
			};
		default:
			return state;
	}
}
