import { DARK_THEME, LIGHT_THEME } from '../Types/themeTypes';

const initialState = {
	currentTheme: LIGHT_THEME
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case LIGHT_THEME:
			return {
				currentTheme: LIGHT_THEME
			};
		case DARK_THEME:
			return {
				currentTheme: DARK_THEME
			};
		default:
			return state;
	}
}
