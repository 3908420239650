export const FETCH_PRICES_LOADING = 'FETCH_PRICES_LOADING';
export const FETCH_PRICES_SUCCESS = 'FETCH_PRICES_SUCCESS';
export const FETCH_PRICES_FAILED = 'FETCH_PRICES_FAILED';

export const GET_USER_CREDITS_LOADING = 'GET_USER_CREDITS_LOADING';
export const GET_USER_CREDITS_SUCCESS = 'GET_USER_CREDITS_SUCCESS';
export const GET_USER_CREDITS_FAILED = 'GET_USER_CREDITS_FAILED';

export const CREATE_SESSION_LOADING = 'CREATE_SESSION_LOADING';
export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export const CREATE_SESSION_FAILED = 'CREATE_SESSION_FAILED';

export const GET_USER_BILLING_FAILED = 'GET_USER_BILLING_FAILED';
export const GET_USER_BILLING_LOADING = 'GET_USER_BILLING_LOADING';
export const GET_USER_BILLING_SUCCESS = 'GET_USER_BILLING_SUCCESS';

export const CANCEL_USER_SUB_FAILED = 'CANCEL_USER_SUB_FAILED';
export const CANCEL_USER_SUB_LOADING = 'CANCEL_USER_SUB_LOADING';
export const CANCEL_USER_SUB_SUCCESS = 'CANCEL_USER_SUB_SUCCESS';

export const UPGRADE_USER_SUB_FAILED = 'UPGRADE_USER_SUB_FAILED';
export const UPGRADE_USER_SUB_LOADING = 'UPGRADE_USER_SUB_LOADING';
export const UPGRADE_USER_SUB_SUCCESS = 'UPGRADE_USER_SUB_SUCCESS';

export const TRANSFER_CREDIT_SUCCESS = 'TRANSFER_CREDIT_SUCCESS';
export const TRANSFER_CREDIT_FAILED = 'TRANSFER_CREDIT_FAILED';
export const TRANSFER_CREDIT_RESET = 'TRANSFER_CREDIT_RESET';
