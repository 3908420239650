// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usrPlnr-flexBox .nunito-sans {
	font-family: 'Nunito-sans', sans-serif;
	/* color: var(--font-clr1); */
}

.usrPlnr-flexBox .txt-decoration {
	text-decoration: underline;
}

.usrPlnr-container .usrPlnr-flexBox {
	border-bottom: 1px solid var(--border-clr1);
}

.usrPlnr-container .usrPlnr-flexBox:last-child {
	border-bottom: none;
}

.usrPlnr-flexBox .primary-clr {
	color: var(--font-clr1);
}

.alt-txt-clr {
	color: var(--alt-font-clr1);
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/components/userplaner/index.css"],"names":[],"mappings":"AAAA;CACC,sCAAsC;CACtC,6BAA6B;AAC9B;;AAEA;CACC,0BAA0B;AAC3B;;AAEA;CACC,2CAA2C;AAC5C;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,uBAAuB;AACxB;;AAEA;CACC,2BAA2B;AAC5B","sourcesContent":[".usrPlnr-flexBox .nunito-sans {\n\tfont-family: 'Nunito-sans', sans-serif;\n\t/* color: var(--font-clr1); */\n}\n\n.usrPlnr-flexBox .txt-decoration {\n\ttext-decoration: underline;\n}\n\n.usrPlnr-container .usrPlnr-flexBox {\n\tborder-bottom: 1px solid var(--border-clr1);\n}\n\n.usrPlnr-container .usrPlnr-flexBox:last-child {\n\tborder-bottom: none;\n}\n\n.usrPlnr-flexBox .primary-clr {\n\tcolor: var(--font-clr1);\n}\n\n.alt-txt-clr {\n\tcolor: var(--alt-font-clr1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
