export const PUBLIC_COURSES_LOADING = 'PUBLIC_COURSES_LOADING';
export const PUBLIC_COURSES_SUCCESS = 'PUBLIC_COURSES_SUCCESS';
export const PUBLIC_COURSES_FAIL = 'PUBLIC_COURSES_FAIL';

export const USERS_COURSES_LOADING = 'USERS_COURSES_LOADING';
export const USERS_COURSES_SUCCESS = 'USERS_COURSES_SUCCESS';
export const USERS_COURSES_FAIL = 'USERS_COURSES_FAIL';

export const GET_COURSE_PROGRESS_LOADING = 'GET_COURSE_PROGRESS_LOADING';
export const GET_COURSE_PROGRESS_SUCCESS = 'GET_COURSE_PROGRESS_SUCCESS';
export const GET_COURSE_PROGRESS_FAIL = 'GET_COURSE_PROGRESS_FAIL';

export const REGISTER_FOR_COURSES_LOADING = 'REGISTER_FOR_COURSES__LOADING';
export const REGISTER_FOR_COURSES_SUCCESS = 'REGISTER_FOR_COURSES__SUCCESS';
export const REGISTER_FOR_COURSES_FAIL = 'REGISTER_FOR_COURSES__FAIL';
