import {
	BLOGS_LOADING,
	BLOGS_SUCCESS,
	BLOGS_FAIL,
	BLOGS_LIKE_BYID_LOADING,
	BLOGS_LIKE_BYID_SUCCESS,
	BLOGS_LIKE_BYID_FAIL,
	BLOGS_BOOKMARK_BYID_LOADING,
	BLOGS_BOOKMARK_BYID_SUCCESS,
	BLOGS_BOOKMARK_BYID_FAIL,
	BLOGS_DELETE_BYID_LOADING,
	BLOGS_DELETE_BYID_SUCCESS,
	BLOGS_DELETE_BYID_FAIL
} from '../Types/blogsTypes';

const initialState = {
	isBlogsLoading: true,
	blogsArray: [],
	bookmarkBlogs: [],
	success: true
};
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case BLOGS_BOOKMARK_BYID_LOADING:
			return {
				...state
			};
		case BLOGS_BOOKMARK_BYID_SUCCESS: {
			const i = state.blogsArray.blogsArray.findIndex(
				(x) => x._id === action.id
			);
			state.blogsArray.blogsArray[i].cardInfo.bookmarkedBy =
				action.payload.usersBookMarkedBlogs;

			return {
				...state,

				bookmarkBlogs: action.payload,
				success: true
			};
		}
		case BLOGS_BOOKMARK_BYID_FAIL:
			return {
				...state,
				success: false
			};
		case BLOGS_LIKE_BYID_LOADING:
			return {
				...state
			};
		case BLOGS_LIKE_BYID_SUCCESS: {
			const i = state.blogsArray.blogsArray.findIndex(
				(x) => x._id === action.id
			);
			state.blogsArray.blogsArray[i].cardInfo.upvotersArray =
				action.payload.upvotersArray;

			return {
				...state,

				blogsArray: state.blogsArray,
				success: true
			};
		}
		case BLOGS_LIKE_BYID_FAIL:
			return {
				...state,
				success: false
			};
		case BLOGS_LOADING:
			return {
				...state,
				isBlogsLoading: true
			};
		case BLOGS_SUCCESS:
			return {
				...state,
				isBlogsLoading: false,
				success: true,
				blogsArray: action.payload
			};
		case BLOGS_FAIL:
			return {
				...state,
				isBlogsLoading: false,
				success: false
			};
		case BLOGS_DELETE_BYID_LOADING:
			return {
				...state
			};
		case BLOGS_DELETE_BYID_SUCCESS:
			return {
				...state,
				success: true
			};
		case BLOGS_DELETE_BYID_FAIL:
			return {
				...state,
				success: false
			};
		default:
			return state;
	}
}
