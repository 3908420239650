/**
 * @fileoverviewThis file contains the OAuth component for user authentication.
 * It handles the OAuth flow for logging in via third-party providers.
 * @description The OAuth component includes the following functionality:
 * - Checking if the user is already authenticated
 * - Handling the OAuth redirect URL
 * - Dispatching the user action for OAuth authentication
 * - Navigating to the home page after successful authentication
 * - Displaying a loading message while checking authentication
 * @module OAuth
 * @exports default
 * @copyright (C) 2024 qBraid Development Team
 */

import React, { useEffect, useState } from 'react';
import {
	Dialog,
	DialogContent,
	Grid,
	Typography,
	dialogClasses
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserOAuthFunction } from '../../../Redux/Action/userAction';
import { currentDomain } from '../../../utils/domainBasedInfo/currentDomain';

/**
 * The OAuth component for user authentication.
 * @returns {JSX.Element} The OAuth component.
 */
function OAuth() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [message, setMessage] = useState('Checking auth...');
	const { userDetails } = useSelector((state) => state.userReducer);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		if (!userDetails?.email) {
			setMessage('Logging in...');
			if (urlParams.has('error_description')) {
				setMessage(
					'Your email already exists in our system. Please try logging in via email and password.'
				);
				localStorage.clear();
				setTimeout(() => {
					navigate('/');
				}, 2500);
				return;
			}
			dispatch(UserOAuthFunction()).then((session) => {
				if (session) {
					setMessage('Logged in successfully!');
					setTimeout(() => {
						window.location.href = '/';
					}, 250);
				} else {
					// case: email in cookies as well as cognito data in local storage not present
					// Therefore, no user is logged in!
					setMessage('You are not authenticated!');
					setTimeout(() => {
						navigate('/');
					}, 2500);
				}
			});
		} else {
			navigate('/');
		}
	}, []);

	return (
		<Dialog
			open
			id="alert-oauth-dialog"
			sx={{
				[`& .${dialogClasses.container}`]: { backdropFilter: 'blur(10px)' }
			}}
		>
			<DialogContent>
				<Grid container rowSpacing={4}>
					<Grid
						item
						xs={12}
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<img
							src={currentDomain.loginModal.logo.image}
							alt={currentDomain.title}
							width={currentDomain.loginModal.logo.width}
							height={currentDomain.loginModal.logo.height}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography textAlign="center" fontSize={18} color="text.primary">
							{message}
						</Typography>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}

export default OAuth;
