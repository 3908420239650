/**
 * @fileoverview This file contains the code for the OTPModal component.
 * It includes functionality for displaying a modal for entering a one-time password (OTP).
 *
 * The OTPModal component includes the following features:
 * - Displaying a modal with a form for entering the OTP
 * - Validating the OTP input and displaying error messages
 * - Toggling the visibility of the OTP input
 * - Handling form submission and dispatching an action to verify the OTP
 *
 * @copyright (C) 2024 qBraid Development Team
 */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Checkbox,
	Grid,
	InputAdornment,
	Typography,
	Modal,
	TextField
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import ModalDesc from '../../user-account/modal-desc';
import { verifyOTPAction } from '../../../Redux/Action/userAction';

const HASHEDPATH = 'hashed_path';

/**
 * Renders the OTPModal component.
 * @param {Object} props - The props of the OTPModal component.
 * @returns {JSX.Element} The rendered OTPModal component.
 * @component
 * @example
 * return <OTPModal />;
 */
function OTPModal({
	open, email, partnerName, hashedPath 
}) {
	const [loading, setLoading] = useState(false);
	const [otp, setOtp] = useState('');
	const [otpError, setOtpError] = useState({ isError: false, message: '' });
	const [checked, setChecked] = useState(false);
	const [showOtp, setShowOtp] = useState(false);
	const { userToken } = useSelector((state) => state.userReducer);
	const dispatch = useDispatch();

	const handleChangeOtp = (e) => {
		setOtp(e.target.value);
		if (otpError.isError) {
			setOtpError({ isError: false, message: '' });
		}
	};

	const handleChecked = () => {
		setChecked((prev) => !prev);
	};

	const showOtpHandler = () => setShowOtp((prev) => !prev);

	const formSubmitHandler = (e) => {
		e.preventDefault();
		if (!otp) {
			setOtpError({ isError: true, message: 'Please enter OTP' });
			return;
		}
		setLoading(true);
		let getHashedPath = hashedPath;
		if (!getHashedPath) {
			getHashedPath = localStorage.getItem(HASHEDPATH);
		}
		try {
			dispatch(
				verifyOTPAction({
					_email: email,
					otp: otp,
					partnerName: partnerName,
					isKeepSignedInFlagChecked: checked,
					hashedPath: getHashedPath,
					userToken: userToken
				})
			).then((res) => {
				setLoading(false);
				if (res?.status === 201) {
					localStorage.removeItem(HASHEDPATH);
					window.location.href = res?.data?.redirectUrl;
				}
				// todo: invalid otp and error
			});
		} catch (err) {
			// eslint-disable-next-line no-console
			console.log(err);
		}
	};

	return (
		<Modal
			sx={{
				background:
					'linear-gradient(to top,#173583,#004d95,#0064a3,#007aad,#008fb4)'
			}}
			open={open}
			disableEscapeKeyDown
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<form onSubmit={formSubmitHandler}>
				<Grid className="box-style" item xs={11} sm={11} md={9} lg={9}>
					<Grid container className="main-grid" justifyContent="center">
						<Grid
							item
							lg={5}
							md={5}
							sm={5}
							xs={5}
							justifyContent="center"
							minHeight={550}
							className="imgGrid"
						>
							<ModalDesc
								title="Welcome to QHack"
								desc="QHack is a world-renowned annual quantum hackathon that takes your education to the next level"
							/>
						</Grid>
						<Grid
							item
							container
							lg={7}
							md={7}
							sm={7}
							xs={12}
							className="formGrid"
						>
							<Grid item container xs={12} justifyContent="center">
								<Grid container item xs={12}>
									<Typography fontSize={28} fontFamily="Righteous">
										Enter one time password
									</Typography>
								</Grid>
								<Grid container item xs={12}>
									<Typography
										fontSize={16}
										fontFamily="Righteous"
										color="text.secondary"
									>
										You can find the OTP in your registered email id.
									</Typography>
								</Grid>
								<Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
									<TextField
										className="textfiledMargin"
										label="Enter code"
										fullWidth
										type={showOtp ? 'text' : 'password'}
										name="otp"
										value={otp}
										error={otpError.isError}
										helperText={otpError.isError && otpError.message}
										onChange={handleChangeOtp}
										InputLabelProps={{
											style: {
												color: '#414345',
												fontSize: '14px',
												fontFamily: 'Righteous !important'
											}
										}}
										sx={{
											fontFamily: 'Righteous !important'
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													{showOtp ? (
														<VisibilityOffIcon
															onClick={showOtpHandler}
															sx={{
																color: '#2E3338',
																cursor: 'pointer'
															}}
														/>
													) : (
														<VisibilityIcon
															onClick={showOtpHandler}
															sx={{
																color: '#2E3338',
																cursor: 'pointer'
															}}
														/>
													)}
												</InputAdornment>
											)
										}}
									/>
								</Grid>
								<Grid
									sx={{
										display: 'flex',
										justifyContent: 'start!important',
										alignItems: 'center',
										marginBottom: '1rem'
									}}
									container
									item
									xs={12}
								>
									<Checkbox
										value={checked}
										onChange={handleChecked}
										sx={{
											'& .MuiSvgIcon-root': { fontSize: 26 },
											paddingLeft: '0px!important',
											marginLeft: '-3px!important',
											color: '#95999D',
											boxSizing: 'border-box',
											borderRadius: '5px'
										}}
									/>
									<Typography
										className="keep-text"
										sx={{
											fontFamily: 'Righteous !important'
										}}
									>
										Do not ask code on this device for 30 days.
									</Typography>
								</Grid>
							</Grid>
							<Grid container item xs={12}>
								<Button
									className="xanadu-login-button"
									type="submit"
									value="submit"
									cy-data="submit"
									fullWidth
									disabled={loading}
									sx={{
										fontFamily: 'Righteous !important',
										maxHeight: 50
									}}
								>
									{loading ? (
										<CircularProgress size={20} style={{ color: '#fff' }} />
									) : (
										'Submit'
									)}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</Modal>
	);
}

export default OTPModal;
