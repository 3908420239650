import { ThemeProvider } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DARK_THEME, LIGHT_THEME } from '../../Redux/Types/themeTypes';
import { darkTheme, lightTheme } from '../../styles/MUITheme/theme';

function MUIThemeProvider({ children }) {
	const { currentTheme } = useSelector((state) => state.themeReducer);

	useEffect(() => {
		const body = document.querySelector('body');
		body.classList.add('lightmode');
		if (currentTheme === DARK_THEME) {
			body.classList.remove('lightmode');
			body.classList.add('darkmode');
		} else {
			body.classList.remove('darkmode');
		}
	}, [currentTheme]);

	return (
		<ThemeProvider
			theme={currentTheme === LIGHT_THEME ? lightTheme : darkTheme}
		>
			{children}
		</ThemeProvider>
	);
}

export default MUIThemeProvider;
