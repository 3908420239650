import {
	ACCOUNT_MAINTENANCE,
	LAB_MAINTENANCE,
	QBOOK_MAINTENANCE
} from '../Types/maintenanceTypes';

const maintenanceState = {
	maintenanceFor: '',
	isUnderMaintenance: false,
	message: '',
	time: Date.now()
};

const initialState = {
	lab: maintenanceState,
	account: maintenanceState,
	qbook: maintenanceState
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case LAB_MAINTENANCE:
			return {
				...state,
				lab: {
					maintenanceFor: action.payload.maintenanceFor,
					isUnderMaintenance: action.payload.isUnderMaintenance,
					message: action.payload.message,
					time: action.payload.time
				}
			};
		case ACCOUNT_MAINTENANCE:
			return {
				...state,
				account: {
					maintenanceFor: action.payload.maintenanceFor,
					isUnderMaintenance: action.payload.isUnderMaintenance,
					message: action.payload.message,
					time: action.payload.time
				}
			};
		case QBOOK_MAINTENANCE:
			return {
				...state,
				qbook: {
					maintenanceFor: action.payload.maintenanceFor,
					isUnderMaintenance: action.payload.isUnderMaintenance,
					message: action.payload.message,
					time: action.payload.time
				}
			};
		default:
			return state;
	}
}
