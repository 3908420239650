export const SHOW_LOADING_MAIN_DATA = 'SHOW_LOADING_MAIN_DATA';
export const SHOW_LOADING_LINK_DATA = 'SHOW_LOADING_LINK_DATA';
export const SHOW_LOADING_TUTORIAL_DATA = 'SHOW_LOADING_TUTORIAL_DATA';
export const HIDE_LOADING_MAIN_DATA = 'HIDE_LOADING_MAIN_DATA';
export const HIDE_LOADING_LINK_DATA = 'HIDE_LOADING_LINK_DATA';
export const HIDE_LOADING_TUTORIAL_DATA = 'HIDE_LOADING_TUTORIAL_DATA';
export const SHOW_MESSAGE_MAIN_DATA = 'SHOW_MESSAGE_MAIN_DATA';
export const SHOW_MESSAGE_LINK_DATA = 'SHOW_MESSAGE_LINK_DATA';
export const SHOW_MESSAGE_TUTORIAL_DATA = 'SHOW_MESSAGE_TUTORIAL_DATA';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

export const ADD_TO_DOWNLOAD_LIST='ADD_TO_DOWNLOAD_LIST'
export const REMOVE_FROM_DOWNLOAD_LIST='REMOVE_FROM_DOWNLOAD_LIST'
export const CHANGE_NAVIGATION='CHANGE_NAVIGATION'

export const FETCH_QUANTUM_DOCS_MAIN_DATA_SUCCESS='FETCH_QUANTUM_DOCS_MAIN_DATA_SUCCESS'
export const FETCH_QUANTUM_DOCS_MAIN_DATA_FAILED='FETCH_QUANTUM_DOCS_MAIN_DATA_FAILED'

export const FETCH_QUANTUM_DOCS_LINK_DATA_SUCCESS='FETCH_QUANTUM_DOCS_LINK_DATA_SUCCESS'
export const FETCH_QUANTUM_DOCS_LINK_DATA_FAILED='FETCH_QUANTUM_DOCS_LINK_DATA_FAILED'

export const FETCH_TUTORIAL_CONTENT_FROM_HOME_SUCCESS='FETCH_TUTORIAL_CONTENT_FROM_HOME_SUCCESS'
export const FETCH_TUTORIAL_CONTENT_FROM_HOME_FAILED='FETCH_TUTORIAL_CONTENT_FROM_HOME_FAILED'

export const FETCH_TUTORIAL_CONTENT_FROM_GITHUB_SUCCESS='FETCH_TUTORIAL_CONTENT_FROM_GITHUB_SUCCESS'
export const FETCH_TUTORIAL_CONTENT_FROM_GITHUB_FAILED='FETCH_TUTORIAL_CONTENT_FROM_GITHUB_FAILED'

export const FETCH_TUTORIAL_DATA_SUCCESS='FETCH_TUTORIAL_DATA_SUCCESS'
export const FETCH_TUTORIAL_DATA_FAILED='FETCH_TUTORIAL_DATA_FAILED'

export const DOWNLOAD_GIT_REPO_CONTENT_SUCCESS='DOWNLOAD_GIT_REPO_CONTENT_SUCCESS'
export const DOWNLOAD_GIT_REPO_CONTENT_FAILED='DOWNLOAD_GIT_REPO_CONTENT_FAILED'

export const ADD_QUANTUM_PROVIDER_SUCCESS='ADD_QUANTUM_PROVIDER_SUCCESS'
export const ADD_QUANTUM_PROVIDER_FAILED='ADD_QUANTUM_PROVIDER_FAILED'

export const UPDATE_QUANTUM_PROVIDER_SUCCESS='UPDATE_QUANTUM_PROVIDER_SUCCESS'
export const UPDATE_QUANTUM_PROVIDER_FAILED='UPDATE_QUANTUM_PROVIDER_FAILED'

export const ADD_QUANTUM_LINK_SUCCESS='ADD_QUANTUM_LINK_SUCCESS'
export const ADD_QUANTUM_LINK_FAILED='ADD_QUANTUM_LINK_FAILED'

export const DELETE_QUANTUM_LINK_SUCCESS='DELETE_QUANTUM_LINK_SUCCESS'
export const DELETE_QUANTUM_LINK_FAILED='DELETE_QUANTUM_LINK_FAILED'

export const DELETE_QUANTUM_MAIN_DATA_SUCCESS='DELETE_QUANTUM_MAIN_DATA_SUCCESS'
export const DELETE_QUANTUM_MAIN_DATA_FAILED='DELETE_QUANTUM_MAIN_DATA_FAILED'

export const SET_DOCS_TUTOR_SNACKBAR_STATUS='SET_DOCS_TUTOR_SNACKBAR_STATUS'
