/**
@fileoverviewThis file contains the reducer for compute profiles on the qBraid platform.
It includes the functionality for setting the compute profile array and the selected compute profile object.
@description The Compute Reducer includes:
- Setting the compute profile array state
- Setting the selected compute profile object state
Copyright (C) 2024 qBraid Development Team 
*/

import {
	SET_COMPUTE_ARRAY,
	SET_SELECTED_COMPUTE_PROFILE_OBJECT
} from '../Types/computeTypes';

const initialState = {
	computeProfile: [],
	selectedProfileObj: { display_name: 'Free 2vCPU 4GB RAM', slug: '2vCPU_4GB' },
	isComputeLoading: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_COMPUTE_ARRAY:
			return {
				...state,
				computeProfile: action.payload
			};
		case SET_SELECTED_COMPUTE_PROFILE_OBJECT:
			return {
				...state,
				selectedProfileObj: action.payload
			};
		default:
			return state;
	}
}
