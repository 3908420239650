import React from 'react';
import './index.css';

function CustomButton({
	children,
	size,
	variant,
	customStyle,
	customCSS,
	title,
	clickHandler,
	disabled,
	fullWidth,
	cyData
}) {
	return (
		<button
			type="button"
			title={title}
			className={`custom_btn ${variant} ${size} ${customCSS} ${
				fullWidth && 'fullWidth'
			}`}
			style={{ ...customStyle }}
			disabled={disabled}
			onClick={clickHandler}
			cy-data={cyData}
		>
			{children}
		</button>
	);
}

export default CustomButton;

CustomButton.defaultProps = {
	children: 'button',
	customStyle: {},
	customCSS: '',
	size: 'default',
	variant: 'solid',
	title: '',
	clickHandler: () => {},
	disabled: false,
	cyData: '',
	fullWidth: false
};
