/**
 * Navigation component
 * @fileoverview This file contains the code for the Navigation component.
 * @copyright 2024 qBraid Development Team
 */
import { fetchAuthSession } from 'aws-amplify/auth';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Route, Routes, useLocation, useNavigate 
} from 'react-router-dom';
import { Container } from '@mui/material';
import Login from './login';
import NewDashboard from '../pages/dashboard';
import OAuth from '../pages/user-account/OAuth';
import {
	DRAWER_MAXIMIZE,
	DRAWER_MINIMIZE
} from '../Redux/Types/filesDrawerTypes';
import { currentDomain } from '../utils/domainBasedInfo/currentDomain';
import { LIGHT_THEME } from '../Redux/Types/themeTypes';
import '../styles/GlobalCSS/index.css';
import './index.css';
import { Footer } from '../components/footer';
import { useResizeObserverError } from '../hooks/useResizeObserverError';
import {
	GenerateAuthCookies,
	UserDataFunction
} from '../Redux/Action/userAction';

/** Lazy loading components */
const QuantumComposer = React.lazy(() => import('../pages/composer'));
const QuantumDevices = React.lazy(() => import('../pages/devices'));
const Tutorial = React.lazy(() => import('../pages/tutorials'));
const LabFileContainer = React.lazy(() =>
	import('../components/labFilesComponent'));
const Billing = React.lazy(() => import('../pages/billing'));
const NotFound = React.lazy(() => import('../pages/error-page'));
const UserProfile = React.lazy(() => import('../pages/user-profile'));
const Profile = React.lazy(() => import('../pages/profile'));
const AccountBillingDetails = React.lazy(() =>
	import('../pages/account-details'));
const Header = React.lazy(() => import('../components/new-header'));
const Organization = React.lazy(() => import('../pages/organization'));
const QuantumJobs = React.lazy(() => import('../pages/quantum-jobs'));
const AccountDeletePage = React.lazy(() =>
	import('../pages/account-delete-page'));
const Hackathon = React.lazy(() => import('../pages/hackathons'));
const Courses = React.lazy(() => import('../pages/courses'));
const MITiQuHack = React.lazy(() =>
	import(
		'../pages/hackathons/pages/HackathonEvent/2023/MITiQuHack/components/MitIquhackComponent'
	));
const MITiQuHack2024 = React.lazy(() =>
	import(
		'../pages/hackathons/pages/HackathonEvent/2024/MITiQuHack/components/MitIquhackComponent'
	));
const Haqs = React.lazy(() =>
	import(
		'../pages/hackathons/pages/HackathonEvent/2022/haqs/components/HaqsComponent'
	));
const SouthCarolinaQuantum = React.lazy(() =>
	import('../pages/hackathons/pages/HackathonEvent/2024/ScQuantum'));
const NYCHAQ = React.lazy(() =>
	import('../pages/hackathons/pages/HackathonEvent/2024/NYCHAQ'));
const Crs4 = React.lazy(() =>
	import('../pages/hackathons/pages/HackathonEvent/2024/Crs4'));

const Termly = React.lazy(() => import('../pages/legal'));
const Maintenance = React.lazy(() => import('../pages/maintenance'));

const fileBrowserHiddenRoutes = ['subscription', 'tutorials'];

/**
 * Navigate component, add routes and redirects here
 * @function Navigate
 */
function Navigate() {
	useResizeObserverError();
	const footerRef = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [fileBrowserException, setFileBrowserException] = useState(false);
	const { pathname } = useLocation();
	const { drawerSize } = useSelector((state) => state.filesDrawerReducer);
	const { currentTheme } = useSelector((state) => state.themeReducer);
	
	useEffect(() => {
		addDomainNameToBody(currentDomain.title);
		navigateBiilingToSubscription();
	}, []);

	useEffect(() => {
		setFileBrowserException(
			fileBrowserHiddenRoutes.some((p) => pathname.includes(p))
		);
	}, [pathname]);

	const navigateBiilingToSubscription = () => {
		if (window.location.pathname.includes('billing')) {
			navigate('/subscription');
		}
	};

	const addDomainNameToBody = (domainName) => {
		const body = document.querySelector('body');
		if (body.classList.contains(domainName)) return;
		body.classList.add(domainName);
		body.setAttribute('data-domain', domainName);
	};

	async function currentAuthenticatedUser() {
		try {
			const session = await fetchAuthSession();
			if (session?.tokens?.accessToken?.payload?.client_id) {
				// set the cookies
				GenerateAuthCookies(session);
				// after setting the cookies, call get user data
				dispatch(UserDataFunction());
			}
		} catch (err) {
			console.log("[!] Cognito authentication errored for the user",err);
		}
	}
	// before loading pages, load userData from api and store in redux
	// only load user data from api if the cognito user session exist
	useEffect(() => {
		currentAuthenticatedUser();
	}, []);
	
	return (
		<div
			className={`db-page-container ${
				currentTheme === LIGHT_THEME ? 'light-theme' : 'dark-theme'
			}`}
		>
			<Header />
			<Login>
				<Container
					maxWidth={false}
					sx={{
						display: drawerSize === DRAWER_MAXIMIZE ? 'none' : 'block',
						maxWidth: 'min(95em,95%)',
						pb: drawerSize === DRAWER_MINIMIZE ? 15 : 2
					}}
				>
					<Routes>
						<Route path="/" element={<NewDashboard />} />
						<Route path="/oauth" element={<OAuth />} />
						<Route path="/success" element={<NewDashboard redirectUri="/" />} />
						<Route path="/cancel" element={<NewDashboard redirectUri="/" />} />
						<Route
							path="/organization"
							element={<Organization expand={false} />}
						/>
						<Route
							path="/organization/:org"
							element={<Organization expand />}
						/>
						<Route
							exact
							path="/organization/:org/success"
							element={<Organization expand />}
						/>
						<Route
							exact
							path="/organization/:org/cancel"
							element={<Organization expand />}
						/>
						<Route path="/courses" element={<Courses expand={false} />} />
						<Route path="/courses/:course_name" element={<Courses expand />} />
						<Route path="/quantumjobs" element={<QuantumJobs />} />
						<Route path="/quantumdevices" element={<QuantumDevices />} />
						<Route path="/quantumcomposer" element={<QuantumComposer />} />
						<Route path="/hackathons" element={<Hackathon />} />
						<Route exact path="/hackathons/2022/haqs" element={<Haqs />} />
						<Route
							exact
							path="/hackathons/2023/iquhack"
							element={<MITiQuHack />}
						/>
						<Route
							exact
							path="/hackathons/2024/iquhack"
							element={<MITiQuHack2024 />}
						/>
						<Route
							exact
							path="/hackathons/2024/scquantum"
							element={<SouthCarolinaQuantum />}
						/>
						<Route exact path="/hackathons/2024/nychaq" element={<NYCHAQ />} />
						<Route exact path="/hackathons/2024/crs4" element={<Crs4 />} />
						<Route path="/courses" element={<Courses />} />
						<Route
							path="/account-details"
							element={<AccountBillingDetails />}
						/>
						<Route
							exact
							path="/account-details/success"
							element={<AccountBillingDetails />}
						/>
						<Route
							exact
							path="/account-details/cancel"
							element={<AccountBillingDetails />}
						/>

						<Route exact path="/user-profile" element={<UserProfile />} />
						<Route
							path="/account/delete/survey"
							element={<AccountDeletePage />}
						/>
						<Route exact path="/profile/:userName" element={<Profile />} />
						<Route
							exact
							path="/privacy-policy"
							element={<Termly dataId="aab5c6d8-8f23-4de0-bf15-093b9363690b" />}
						/>
						<Route
							exact
							path="/cookie-policy"
							element={<Termly dataId="b91f6ee2-e92a-46be-b75d-4d2f0d897ab3" />}
						/>
						<Route
							exact
							path="/terms-and-conditions"
							element={<Termly dataId="bd885a1a-cf4d-4cfd-bc3c-eb383bab2afe" />}
						/>
						<Route exact path="/subscription" element={<Billing />} />
						<Route exact path="/tutorials" element={<Tutorial />} />
						<Route path="/maintenance" element={<Maintenance />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Container>
			</Login>
			{!fileBrowserException && <LabFileContainer footerRef={footerRef} />}
			{!window.location.pathname.includes('/auth/login/partner/') && (
				<Footer ref={footerRef} />
			)}
		</div>
	);
}

export default Navigate;
