import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef((props, ref) => (
	<MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));
const index = (props) => (
	<div>
		<Snackbar
			open={props.openSnack}
			autoHideDuration={4000}
			anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
			onClose={props.handleCloseSnack}
			cy-data="snackBar"
		>
			<Alert
				onClose={props.handleCloseSnack}
				severity={props.snackColor ? 'error' : 'success'}
				sx={{ width: '100%' }}
				cy-data="alert"
			>
				{props.snackMessage}
			</Alert>
		</Snackbar>
	</div>
);

export default index;
