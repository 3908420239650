/**
 * @fileoverviewThis file contains the ModalDesc component, which is responsible for rendering a modal description in the user account page.
 * The ModalDesc component displays a title and description, along with logos and background images based on the current domain and authentication status.
 * It also includes CSS styles for customizing the appearance of the modal description.
 * @copyright (C) 2024 qBraid Development Team
 */
import React, { useMemo } from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import QBraidFullLogoWhite from '../../../assets/images/qbraid_white_logo.png';
import QHackLogo from '../../../assets/images/qHackLogo.svg';
import { currentDomain } from '../../../utils/domainBasedInfo/currentDomain';
import './index.css';

function ModalDesc({ title, desc }) {
	const theme = useTheme();
	const xanaduAuth = useMemo(
		() => window.location.pathname.includes('/login/partner/xanadu'),
		[window.location.pathname]
	);
	return (
		<Stack
			justifyContent="center"
			alignItems="center"
			position="relative"
			overflow="hidden"
			height="100%"
			width="100%"
			gap={1}
			className={
				xanaduAuth
					? 'xanadu-img-bg'
					: theme.palette.mode === 'dark'
						? 'img-linear-bg-dark'
						: 'img-linear-bg'
			}
			sx={{ userSelect: 'none', pointerEvents: 'none' }}
		>
			{xanaduAuth ? (
				<img
					src={QBraidFullLogoWhite}
					alt="qbraidLogo"
					className="qbraid-full-logo-xanadu"
				/>
			) : (
				<div className="circle-shape large grad-1 pos-top-right" />
			)}
			<img
				src={xanaduAuth ? QHackLogo : currentDomain.loginModal.logo.image}
				alt={currentDomain.title}
				width={currentDomain.loginModal.logo.width}
				height={currentDomain.loginModal.logo.height}
				loading="eager"
			/>
			<Stack gap={2} px={1}>
				<Typography
					fontWeight={800}
					fontFamily={xanaduAuth ? 'Righteous' : 'Inter'}
					fontSize={24}
					textAlign="center"
					color={xanaduAuth ? '#f5f5f5' : 'text.primary'}
				>
					{title}
				</Typography>
				<Typography
					color={xanaduAuth ? '#f5f5f5' : 'text.primary'}
					fontWeight={400}
					textAlign="center"
					fontFamily={xanaduAuth ? 'Righteous' : 'Inter'}
					fontSize={14}
					maxWidth={280}
				>
					{desc}
				</Typography>
			</Stack>
			{!xanaduAuth && (
				<div className="pos-bottom-left">
					<span className="circle-shape large grad-2" />
					<span className="circle-shape small grad-3 position_shift " />
				</div>
			)}
		</Stack>
	);
}

ModalDesc.defaultProp = {
	title: 'Welcome to qBraid.',
	desc: ''
};

export default ModalDesc;
